

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : file:		dc7__math — static class — js 1.85
///h : version:		8.00.000000 — 2017.05.01__12:00.10
///h : credits:		plustic — massimo cardascia — www.plustic.de
///h : style:		whitesmiths style variant — 8 spaces tab — http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__math(){}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__math['o_data'] = null;				///h : stores class data


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : static class init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initclass
	 * is called when a new object of the class
	 * is dynamicly created.
	 *
	 * @access public
	 * @return void
	 *
	 */
	dc7__math.f_initClass = function()
		{
		///h :
		dc7__math.f_initConfig();
	
		///h :
		dc7__math.f_initContent();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init data
	/**
	 * public static function — f_initConfig
	 * all content related data is initiated
	 * on class init directly.
	 *
	 * @access public
	 * @return void
	 *
	 */
	dc7__math.f_initConfig = function($o_config)
		{
		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : init

		///h :
		if(dc7__math['o_data'] === null)
			{
			//////////////////////////////////////////////////////////////////////////////////////
			///h : class

			///h :
			dc7__math['o_data'] = {};
			dc7__math['o_data']['class__v_update'] = '8.00.000000';
			dc7__math['o_data']['class__tsc_update'] = '2017.05.01__12:00.10';
			}
 
 
		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : update config

		///h :
	//	dc7__math['o_data'] = dc7__var.f_updateConfig(dc7__math['o_data'], $o_config);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__math.f_initContent = function()
		{
		///h : default config
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__math.f_lerp = function($num_current, $num_target, $num_amount)
		{
		///h :
		return((1.00 - $num_amount) * $num_current + $num_amount * $num_target);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__math.f_limit = function($num_data, $num_max, $num_min)
		{
		///h :
		$num_min = dc7__var.f_initVar($num_min, $num_data);

		///h :
		$num_data = (($num_data > $num_max) ? $num_max : $num_data);
		$num_data = (($num_data < $num_min) ? $num_min : $num_data);

		///h :
		return($num_data);
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__math.f_random = function($num_max, $num_min)
		{
		///h :
		return(Math.random() * ($num_max - $num_min) + $num_min);
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__math.f_getRuleOfThree = function($num_data__a__1, $num_data__a__2, $num_data__b__1)
		{
		///h :
		return(($num_data__a__2 / $num_data__a__1) * $num_data__b__1);
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__math.f_getPercentageRelation = function($num_data__a, $num_data__b)
		{
		///h :
		return($num_data__a / $num_data__b);
		}

	
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__math.f_getPercentageFactor = function($o_percentage)
		{
		///h :
		var $num_percentage = null;		///h :
		var $num_factor = null;			///h :

		///h :
		$num_percentage = parseInt($o_percentage, 10);
		$num_factor = ($num_percentage / 100);
		
		///h : 
		return($num_factor);
		}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h :
jQuery(document).ready(function(){dc7__math.f_initClass();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
/*
*/


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : log
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
/*
 */