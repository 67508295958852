//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__scroller(){this.f_construct();}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__scroller.__o_data = null;				///h : stores class data
	dc7__scroller.__o_config = null;				///h : stores class config


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__scroller.f_initSystem = function()
		{
		dc7__scroller.f_init();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init
	/**
	 * init
	 * init class calling all default functions like initenvironment, initdata
	 * initcontent.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__scroller.f_init = function()
		{
		///: init config
		dc7__scroller.f_initData();

		///: init config
		dc7__scroller.f_initContent();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init data
	 * used to init all data later on used in the class context like
	 * configuration arrays etc.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__scroller.f_initData = function()
		{
		///h :
		dc7__scroller.__o_data = {};
		dc7__scroller.__o_data['o_preset'] = {};
		dc7__scroller.__o_data['o_preset']['pageinitreset'] = {'num_duration':0, 'i_x':0, 'i_y':0};
		dc7__scroller.__o_data['b_uri_check'] = true;
		dc7__scroller.__o_data['b_uri_check__on_start'] = true;
		dc7__scroller.__o_data['s_ease'] = 'Power4.easeOut';
		dc7__scroller.__o_data['num_duration'] = 0.8;
		dc7__scroller.__o_data['tween_scroller'] = null;
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__scroller.f_initContent = function()
		{
		///h :
		dc7.__o_data['dom__jqo_window'].bind('dc7__request__changefragment dc7__request__resetfragment', {}, function($o_event){dc7__scroller.f_updateURI(false);});

		///h :
	//	TweenLite.ticker.addEventListener('tick', function(){dc7__scroller.f_updateSmoothScroll();});
	//	dc7__scroller.f_updateSmoothScroll();
 
		///h : disable normal mousehweel scrolling
	//	dc7.__o_data['dom__jqo_window'].on('wheel', {}, function($event_data){dc7__scroller.f_smoothBrowserScroll($event_data); $event_data.preventDefault(); return(false);});	
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__scroller.f_lerp = function($num_start, $num_end, $num_amt)
		{
		///h :
		return((1 - $num_amt) * $num_start + $num_amt * $num_end);
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param	
	 * @return vault
	 *
	 */
	dc7__scroller.f_updateSmoothScroll = function()
		{
		///h :
		document.documentElement.scrollTop = Math.round(dc7__scroller.f_lerp(document.documentElement.scrollTop, dc7__scroller.__o_data['i_y__smooth'], 0.10));
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param	
	 * @return vault
	 *
	 */
	dc7__scroller.f_smoothBrowserScroll = function($event_data)
		{
		///h :
		var $s_y = null;		///h :
		var $o_config = null;		///h :
		
		///h :
		$i_y = jQuery(window).scrollTop();

		///h :
		if($event_data['originalEvent']['deltaY'] < 0)
			{
			///h :
			dc7__scroller.__o_data['i_y__smooth'] = (dc7__scroller.__o_data['i_y__smooth'] + 200);
			}
		else
			{
			///h :
			dc7__scroller.__o_data['i_y__smooth'] = (dc7__scroller.__o_data['i_y__smooth'] - 200);
			}
			
		///h :
		if(dc7__scroller.__o_data['i_y__smooth'] < 0)
			{
			///h :
			dc7__scroller.__o_data['i_y__smooth'] = 0;
			}

		///h :
	//	dc7__scroller.__o_data['i_y__smooth'] = $i_y;
	//	TweenMax.to(dc7.__o_data['dom__jqo_window'], 1.60, {'scrollTo':{'y':$i_y}, 'ease':'Expo.easeInOut', 'delay':'0.00'});
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param	
	 * @return vault
	 *
	 */
	dc7__scroller.f_scrollToURIFragment = function($b_init)
		{
		///h :
		var $s_fragement = null;		///h :
		var $jqo_element = null;		///h :
		var $o_config = null;			///h :
		

		///h :
		$s_fragment = dc7__request.__o_data['o_uri']['s_fragment'];
 
		///h :
		$jqo_element = jQuery('.dc7__scroller.__s_fragment__' + $s_fragment);
		
		///h :
		if($jqo_element.length === 1)
			{
			///h :
			$o_config = {};
			$o_config['jqo_target'] = $jqo_element;
			$o_config['num_duration'] = (($b_init === true) ? 0 : undefined);
			

			///h :
			dc7__scroller.f_scroll($o_config);
			}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__scroller.f_updateURI = function($b_init)
		{
		///h :
		var $num_duration = null;	///h :
		var $jqo_element = null;	///h : 
		var $s_fragment = null;		///h :
		var $o_config = null;		///h :

		///h :
		$b_init = (($b_init === undefined) ? false : $b_init);

		///h :
		if(dc7__scroller.__o_data['b_uri_check'] === true)
			{
			///h :
			dc7__scroller.f_scrollToURIFragment($b_init);
			}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__scroller.f_initConfig = function($o_config)
		{
		///h :
		dc7__scroller.__o_data['i_y__smooth'] = 0;

		///h : 
		dc7__var.f_extendVar(dc7__scroller.__o_data, $o_config);
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__scroller.f_scroll = function($o_config)
		{
		///h :
		var $s_loop = null;		///h :
		var $f_complete = null;		///h :

		///h : config
		$o_config = (($o_config === undefined) ? {} : $o_config);
		$o_config['s_preset_id'] = (($o_config['s_preset_id'] === undefined) ? null : $o_config['s_preset_id']);

		///h :
		if(dc7__scroller.__o_data['o_preset'][$o_config['s_preset_id']] !== undefined)
			{
			///h :
			dc7__var.f_extendVar($o_config, dc7__scroller.__o_data['o_preset'][$o_config['s_preset_id']]);
			}

		///h :
		$o_config['jqo_element'] = (($o_config['jqo_element'] === undefined) ? dc7.__o_data['dom__jqo_window'] : $o_config['jqo_element']);
		$o_config['s_target_selector'] = (($o_config['s_target_selector'] === undefined) ? null : $o_config['s_target_selector']);
		$o_config['jqo_target'] = (($o_config['s_target_selector'] !== null) ? jQuery($o_config['s_target_selector']) : $o_config['jqo_target']);
		$o_config['jqo_target'] = (($o_config['jqo_target'] === undefined) ? null : $o_config['jqo_target']);
		$o_config['num_duration'] = (($o_config['num_duration'] === undefined) ? dc7__scroller.__o_data['num_duration'] : $o_config['num_duration']);
		$o_config['s_ease'] = (($o_config['s_ease'] === undefined) ? dc7__scroller.__o_data['s_ease'] : $o_config['s_ease']);
		$o_config['s_ease'] = (($o_config['num_duration'] === 0) ? 'Linear.easeNone' : $o_config['s_ease']);
		$o_config['i_x'] = (($o_config['i_x'] === undefined) ? 0 : $o_config['i_x']);
		$o_config['i_y'] = (($o_config['i_y'] === undefined) ? 0 : $o_config['i_y']);
		$o_config['i_x_offset'] = (($o_config['i_x_offset'] === undefined) ? 0 : $o_config['i_x_offset']);
		$o_config['i_y_offset'] = (($o_config['i_y_offset'] === undefined) ? 0 : $o_config['i_y_offset']);
		$o_config['num_delay'] = (($o_config['num_delay'] === undefined) ? 0 : $o_config['num_delay']);
		$o_config['b_check_max'] = (($o_config['b_check_max'] === undefined) ? true : $o_config['b_check_max']);


		///h : scroll to target
		if($o_config['jqo_target'] !== null)
			{
			$o_config['o_offset'] = $o_config['jqo_target'].offset();
			$o_config['i_x'] = Math.round($o_config['o_offset'].left);
			$o_config['i_y'] = Math.round($o_config['o_offset'].top);
			}

		///h : add offset
		$o_config['i_x'] = ($o_config['i_x'] - $o_config['i_x_offset']);
		$o_config['i_y'] = ($o_config['i_y'] - $o_config['i_y_offset']);

		///h : check max
		if($o_config['b_check_max'] === true)
			{
//			$o_config['i_y_max'] = (dc7.__o_data['dom__jqo_document'].height() - dc7.__o_data['dom__jqo_window'].height());
//			$o_config['i_y'] = (($o_config['i_y'] < 0) ? 0 : $o_config['i_y']);
//			$o_config['i_y'] = (($o_config['i_y'] > $o_config['i_y_max']) ? $o_config['i_y_max'] : $o_config['i_y']);			
			} 

		///h : tween
		//dc7__scroller.__o_data['tween_scroller'] = TweenMax.to($o_config['jqo_element'], $o_config['num_duration'], {'scrollTo':{'x':$o_config['i_x'], 'y':$o_config['i_y'], 'autoKill':true}, 'ease':$o_config['s_ease'], 'overwrite':'auto', 'delay':$o_config['num_delay'], 'overwrite':false});
		//dc7__scroller.__o_data['tween_scroller'] = TweenMax.to($o_config['jqo_element'], $o_config['num_duration'], {'scrollTo':{'x':$o_config['i_x'], 'y':$o_config['i_y']}, 'ease':$o_config['s_ease'], 'overwrite':'auto', 'delay':$o_config['num_delay'], 'overwrite':true});
		
		if(dc7__grid.f_isTouchscreen() === true)
			{
			jQuery('html, body').delay($o_config['num_delay'] * 2000).animate({scrollTop:($o_config['i_y'] + 'px')}, 800);
			}
		else
			{
			dc7__scroller.__o_data['tween_scroller'] = TweenMax.to($o_config['jqo_element'], $o_config['num_duration'], {'scrollTo':{'x':$o_config['i_x'], 'y':$o_config['i_y'], 'autoKill':true}, 'ease':$o_config['s_ease'], 'overwrite':'auto', 'delay':$o_config['num_delay'], 'overwrite':false});
			}
		
		///h : special preset
		if($o_config['s_preset_id'] === 'pageinitreset')
			{
			///h :
			jQuery('window, html, body').css({'display':'none'});
			jQuery('window, html, body').scrollLeft($o_config['i_x']);
			jQuery('window, html, body').scrollTop($o_config['i_y']);
			window.scrollTo($o_config['i_x'], $o_config['i_y']);

			///h :
			document.body.scrollTop = document.documentElement.scrollTop = $o_config['i_y'];
			jQuery('window, html, body').css({'display':'block'}); 
	//		TweenMax.to($o_config['jqo_element'], 0.0, {'scrollTo':{'x':$o_config['i_x'], 'y':$o_config['i_y'], 'autoKill':false}, 'ease':$o_config['s_ease'], 'overwrite':'auto', 'delay':0.01, 'autoKill':false});

			///h :
			if($o_config['b_uri_check__on_start'] === true)
				{
				///h :
				dc7__scroller.f_updateURI(true);
				}
			}
		}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__scroller.f_initSystem();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
