//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__sequencer(){this.f_construct();}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__sequencer.__o_data = null;				///h : stores class data


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__sequencer.f_initSystem = function()
		{
		///h :
		dc7__sequencer.f_init();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init
	/**
	 * init
	 * init class calling all default functions like initenvironment, initdata
	 * initcontent.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__sequencer.f_init = function()
		{
		///: init config
		dc7__sequencer.f_initData();

		///: init config
		dc7__sequencer.f_initContent();
		} 


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init data
	 * used to init all data later on used in the class context like
	 * configuration arrays etc.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__sequencer.f_initData = function()
		{
		///h :
		dc7__sequencer.__o_data = {};
		dc7__sequencer.__o_data['o_sequencer'] = {};
		dc7__sequencer.__o_data['num_counter'] = 0;
		dc7__sequencer.__o_data['a_id'] = [];
		dc7__sequencer.__o_data['i_id'] = 0;
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__sequencer.f_initContent = function()
		{
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__sequencer.f_create = function($s_id, $num_interval, $f_sequence, $o_config)
		{
		///h :
		if(dc7__sequencer.__o_data === null)
			{
			///h :
			dc7__sequencer.f_initData(); 
			}

		///h :
		$o_config = (($o_config === undefined) ? null : $o_config);

		///h :
		dc7__sequencer.__o_data['o_sequencer'][$s_id] = {};
		dc7__sequencer.__o_data['o_sequencer'][$s_id][$s_id] = $s_id;
		dc7__sequencer.__o_data['o_sequencer'][$s_id]['num_counter'] = 0;
		dc7__sequencer.__o_data['o_sequencer'][$s_id]['ms_timer'] = -1;
		dc7__sequencer.__o_data['o_sequencer'][$s_id]['a_data'] = [];
		dc7__sequencer.__o_data['o_sequencer'][$s_id]['f_sequence'] = $f_sequence;
		dc7__sequencer.__o_data['o_sequencer'][$s_id]['o_config'] = $o_config;
		dc7__sequencer.__o_data['o_sequencer'][$s_id]['num_interval'] = $num_interval;
		dc7__sequencer.__o_data['o_sequencer'][$s_id]['ms_interval'] = ($num_interval * 1000);
		dc7__sequencer.__o_data['o_sequencer'][$s_id]['tween_interval'] = TweenMax.to(dc7__sequencer.__o_data['o_sequencer'][$s_id], dc7__sequencer.__o_data['o_sequencer'][$s_id]['num_interval'], {'num_counter':100, 'repeat':-1, 'delay':0, 'onRepeat':function($s_id){dc7__sequencer.f_update($s_id);}, 'onRepeatParams':[$s_id]});
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__sequencer.f_addElement = function($s_id, $o_data)
		{
		///h :
		var $storage_data = null;	///h :

		///h :
		$storage_data = dc7__sequencer.__o_data['o_sequencer'][$s_id];

		///h :
		if($storage_data !== undefined)
			{
			///h :
			$storage_data['a_data'].push($o_data);
			}

		///h :
		dc7__sequencer.f_update($s_id);
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__sequencer.f_update = function($s_id)
		{
		///h :
		var $storage_data = null;	///h :
		var $o_data = null;		///h :

		///h : 
		$storage_data = dc7__sequencer.__o_data['o_sequencer'][$s_id];

		///h : check if sequence elements existing
		if(dc7__sequencer.__o_data['o_sequencer'][$s_id]['a_data'].length > 0)
			{
			///h :
			if((jQuery.now() - dc7__sequencer.__o_data['o_sequencer'][$s_id]['ms_timer']) > dc7__sequencer.__o_data['o_sequencer'][$s_id]['ms_interval'])
				{
				///h : remove first element
				$o_data = dc7__sequencer.__o_data['o_sequencer'][$s_id]['a_data'][0];

				///h : 
				dc7__sequencer.__o_data['o_sequencer'][$s_id]['f_sequence']($o_data);

				///h :
				dc7__sequencer.__o_data['o_sequencer'][$s_id]['a_data'].shift();
 
				///h :
				dc7__sequencer.__o_data['o_sequencer'][$s_id]['ms_timer'] = jQuery.now();
				}
			}		
		}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__sequencer.f_initSystem();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
