//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__events(){this.f_construct();}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__events.__o_data = null;				///h : stores class data
	dc7__events.__o_config = null;				///h : stores class config


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__events.f_initSystem = function()
		{
		dc7__events.f_init();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init
	/**
	 * init
	 * init class calling all default functions like initenvironment, initdata
	 * initcontent.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__events.f_init = function()
		{
		///: init config
		dc7__events.f_initData();

		///: init config
		dc7__events.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init data
	 * used to init all data later on used in the class context like
	 * configuration arrays etc.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__events.f_initData = function()
		{
		dc7__events.__o_data = {};
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__events.f_initContent = function()
		{
		}

		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__events.f_delayedTrigger = function($jqo_element, $s_event_id, $a_parameter, $num_delay)
		{
		///h : properties
		var $s_event_id_tween_id = null;	///h :
		var $tween_delay = null;		///h : 
		
		///h : data
		$s_event_id_tween_id = ('dc7__events__tween__' + $s_event_id);
		
		///h : old tween kill
		$tween_delay = dc7__dom.f_getVar($jqo_element, $s_event_id_tween_id);
		if($tween_delay !== null)
			{
			$tween_delay.kill();
			}
	
		///h : tween - trigger delayed event (e.g.: dc7__behavior__load__ready__delayed1.0s)
		$tween_delay = TweenMax.delayedCall($num_delay, function($jqo_element, $s_event_id, $a_parameter){$jqo_element.trigger($s_event_id, $a_parameter);}, [$jqo_element, $s_event_id_delayed, $a_parameter]);
		dc7__dom.f_setVar($jqo_element, $s_event_id_tween_id, $tween_delay);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__events.f_updateView = function()
		{
		///h :
		dc7__events.f_trigger(dc7.__o_data['dom__jqo_window'], 'dc7__events__updateview', undefined, [0.1]);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__events.f_trigger = function($jqo_element, $s_event_id, $a_parameter, $a_events_delayed)
		{
		///h : properties
		var $tween_delay = null;		///h : stores delay
		var $i_loop = -1;
		var $i_events_delayed = -1;
		var $num_delay = -1;

		///h : check transmitted parameter
		$a_parameter = (($a_parameter === undefined) ? [] : $a_parameter);
		$a_events_delayed  = (($a_events_delayed === undefined) ? null : $a_events_delayed);
		
		///h : trigger event
		$jqo_element.trigger($s_event_id, $a_parameter);
		
		///h : trigger event - delayed
		if($a_events_delayed !== null)
			{
			$i_events_delayed = $a_events_delayed.length;
	
			///h : loop
			for($i_loop = 0; $i_loop < $i_events_delayed; $i_loop++)
				{
				///h : small delay
				$num_delay = $a_events_delayed[$i_loop];
				$s_event_id_delayed = ($s_event_id + '__delayed' + $num_delay.toFixed(1) + 's');
				dc7__events.f_delayedTrigger($jqo_element, $s_event_id_delayed, $a_parameter, $num_delay);
				}
			}
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__events.f_proxy__trigger = function($jqo_dom, $jqo_proxy, $s_event_id)
		{
		///h :
		var $jqo_dom__current = null;		///h :
		var $i_loop = null;			///h :
		var $i_dom = null;			///h :
		var $b_blocked = null;			///h :

		///h :
		$b_blocked = dc7__dom.f_getVar($jqo_dom, 'b_blocked');
		if($b_blocked === true)
			{
			///h :
			return;
			}
		dc7__dom.f_setVar($jqo_dom, 'b_blocked', true);

		///h : loop
		$i_dom = $jqo_proxy.length;
		for($i_loop = 0; $i_loop < $i_dom ; $i_loop++)
			{
			///h :
			$jqo_dom__current = jQuery($jqo_proxy[$i_loop]);

			///h :
			if($jqo_dom[0] !== $jqo_dom__current[0])
				{
				///h :
				$jqo_dom__current.triggerHandler($s_event_id);
				}
			}

		///h :
		dc7__dom.f_setVar($jqo_dom, 'b_blocked', false);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__events.f_proxy = function($jqo_dom, $s_event_id, $o_config)
		{
		///h :
		var $jqo_dom__current = null;		///h :
		var $i_loop = null;			///h :
		var $i_dom = null; 			///h :

		///h :
		$o_config = dc7__var.f_initVar($o_config, {});
		$o_config['b_state_behavior'] = dc7__var.f_initVar($o_config['b_state_behavior'], true);

		///h : 
		if($jqo_dom.length > 1)
			{
			///h : loop
			$i_dom = $jqo_dom.length;
			for($i_loop = 0; $i_loop < $i_dom ; $i_loop++)
				{
				///h :
				$jqo_dom__current = jQuery($jqo_dom[$i_loop]);

				///h :
				$jqo_dom__current.on($s_event_id, {'jqo_dom__current':$jqo_dom__current, 'jqo_dom':$jqo_dom}, function($o_event){dc7__events.f_proxy__trigger($o_event.data['jqo_dom__current'], $o_event.data['jqo_dom'], $o_event['type']);});
				}
			}

		///h :
		if($o_config['b_state_behavior'] === true)
			{
			///h :
			$jqo_dom.addClass('dc7__behavior__state');

			///h :
			dc7__behavior.f_updateElements();
			}
		}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__events.f_initSystem();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
