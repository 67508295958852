//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__request(){this.f_construct();} 
 
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__request.__o_data = null;				///h : stores class data
	dc7__request.__o_config = null;				///h : stores class config


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__request.f_initSystem = function()
		{
		dc7__request.f_init();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init
	/**
	 * init
	 * init class calling all default functions like initenvironment, initdata
	 * initcontent.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__request.f_init = function()
		{
		///: init config
		dc7__request.f_initData();

		///: init config
		dc7__request.f_initContent();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init data
	 * used to init all data later on used in the class context like
	 * configuration arrays etc.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__request.f_initData = function()
		{
		///h :
		dc7__request.__o_data = {};					///h :
		dc7__request.__o_data['o_uri'] = null;				///h :
		dc7__request.__o_data['s_fragment'] = null;			///h :
		dc7__request.__o_data['s_uri_complete'] = null;			///h :
		dc7__request.__o_data['b_update_complete'] = false;		///h :
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__request.f_initContent = function()
		{	
		///h : bind - hashchange
		dc7.__o_data['dom__jqo_window'].bind('popstate hashchange', {}, function($o_event){dc7__request.f_updateURI();});
		dc7__request.f_updateURI();

		///h :
		dc7__request.f_parseDOM();
		}
	

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class methods - uri check
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	

//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__request.f_checkResetURI = function($jqo_element)
		{
		///h :
		var $s_href = null;			///h :
		var $s_fragment__href = null;		///h :
		var $s_fragment__current = null;	///h :
		
		///h : 
		$s_href = $jqo_element.attr('href');

		///h :
		if($s_href !== undefined)
			{
			if(dc7__request.f_isSameServer($s_href) === true)
				{
				///h :
				$s_fragment__href = dc7__request.f_parseURI($s_href, 's_fragment', {'b_clean':false});
				$s_fragment__current = dc7__request.f_parseURI(undefined, 's_fragment', {'b_clean':false});

				///h : 
				if($s_fragment__href === $s_fragment__current)
					{
					///h : trigger
					dc7.__o_data['dom__jqo_window'].trigger('dc7__request__resetfragment');
					}
				}
			}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__request.f_parseDOM = function()
		{
		///h :
		var $jqo_dom = null;		///h :
		var $i_dom = null;		///h :
		var $i_loop = null;		///h :
		var $jqo_element = null;	///h :

		///h :
		$jqo_dom = jQuery('a');
		$i_dom = $jqo_dom.length;

		///h :
		for($i_loop = 0; $i_loop < $i_dom; $i_loop++)
			{
			///h :
			$jqo_element = jQuery($jqo_dom[$i_loop]);

			///h :
			if(dc7__dom.f_checkInit($jqo_element, 'dc7__request__reset') === false)
				{
				///h :
				$jqo_element.bind('click', {'jqo_element':$jqo_element}, function($o_event){dc7__request.f_checkResetURI($o_event.data['jqo_element']);});
				}
			}
		}
	
	
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * get clean domain
	 * parsing the uri and cleaning it for later compare off same server, relative etc
	 *
	 * @access public
	 * @param boolean $s_uri optional - stores uri to check
	 * @return boolean
	 *
	 */
	dc7__request.f_getCleanURI = function($s_uri, $o_config)
		{		
		///h : check
		$o_config = (($o_config === undefined) ? {} : $o_config);
		$o_config['b_lowercase'] = (($o_config['b_lowercase'] === undefined) ? true : $o_config['b_lowercase']);
		$o_config['b_absolute'] = (($o_config['b_absolute'] === undefined) ? true : $o_config['b_absolute']);
		$o_config['b_slashes'] = (($o_config['b_slashes'] === undefined) ? true : $o_config['b_slashes']);
		$o_config['b_trailingslash'] = (($o_config['b_trailingslash'] === undefined) ? true : $o_config['b_trailingslash']);

		///h : make url absolute
		if($o_config['b_absolute'] === true)
			{
			///h : relative uri
			if(dc7__request.f_isRelative($s_uri) === true)
				{
				///h :
				$s_uri = (dc7__request.__o_data['o_uri']['s_root'] + $s_uri);
				}
			///h : no http in absolute path
			else
				{
				///h :
				if($s_uri.indexOf('http') < 0)
					{
					$s_uri = ('http://' + $s_uri);
					}
				}
			}

		///h : clean
		$s_uri = dc7__var.f_getCleanVar($s_uri, 'uri__part', $o_config);

		///h : return
		return($s_uri);
		}
		
	
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__request.f_isSameURI = function($s_uri__a, $s_uri__b)
		{
		///h :
		var $b_same = false;
		
		///h :
		$s_uri__b = (($s_uri__b === undefined) ? window.location.href : $s_uri__b);

		///h :
		$s_uri__a = dc7__request.f_getCleanURI($s_uri__a);

		///h :
		return($b_same);
		}
	
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__request.f_isExternal = function($s_uri)
		{
		///h :
		return(!dc7__request.f_isSameServer($s_uri));
		}

 
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__request.f_isSameServer = function($uri_data)
		{
		///h : properties
		var $o_config = null;		///h :
		var $is_external = null;	///h : 
		var $uri_data__current = null;	///h :

		///h : 
		$o_config = {};
		$o_config['b_lower_case'] = true;

		///h :
		$is_external = false;
		if(dc7__request.f_isRelative($uri_data) === true)
			{
			///h :
			$is_external = false;
			}
		///h :
		else
			{
			///h :
			$uri_root = dc7__request.f_parseURI($uri_data, 's_root');
			$uri_rootcurrent = dc7__request.f_parseURI(location.href, 's_root');
			$is_external = ($uri_root !== $uri_rootcurrent);
			}

		///h : 
		return(!$is_external);
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__request.f_isAbsolute = function($s_uri)
		{
		return(!dc7__request.f_isRelative($s_uri));
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__request.f_isRelative = function($s_uri)
		{
		///h : 
		var $reg_check = null;			///h : 
		var $b_is_relative = false;

		///h :
		if(($s_uri.indexOf('/') === 0) && ($s_uri.indexOf('//') !== 0))
			{
			///h 
			$b_is_relative = true;
			}
		///h :
		else if($s_uri.indexOf('#') === 0)
			{
			///h 
			$b_is_relative = true;
			}
		else
			{
			///h 
			$reg_check = new RegExp('^(?:[a-z]+:)?//', 'i');
			$b_is_relative = !$reg_check.test($s_uri);
			}

		///h : 
		return($b_is_relative);
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 * 
	 * @see http://phpjs.org/functions/parse_url/
	 *
	 */
	dc7__request.f_parseURI__intern = function($s_uri)
		{
		///h : properties
		var $s_query = null;		///h :
		var $s_mode = null;		///h :
		var $a_scheme = null;		///h :
		var $regexp_scheme = null;	///h :
		var $o_uri = null;		///h :
		var $a_uri = [];		///h :
		var $i_loop = -1;		///h :
		var $i_data = -1;		///h :

		///h :
		$a_scheme = ['source', 'scheme', 'authority', 'userInfo', 'user', 'pass', 'host', 'port', 'relative', 'path', 'directory', 'file', 'query', 'fragment'];
		$regexp_scheme = new RegExp('(?:([^:\\/?#]+):)?(?:\\/\\/()(?:(?:()(?:([^:@\\/]*):?([^:@\\/]*))?@)?([^:\\/?#]*)(?::(\\d*))?))?()(?:(()(?:(?:[^?#\\/]*\\/)*)()(?:[^?#]*))(?:\\?([^#]*))?(?:#(.*))?)');
		
		///h :
		$a_uri = $regexp_scheme.exec($s_uri);
		$o_uri = {};
		$i_data = 14;
		for($i_loop = 0; $i_loop < $i_data; $i_loop++)
			{
			$o_uri[$a_scheme[$i_loop]] = $a_uri[$i_loop]
			}

		///h : 
		$o_uri['path'] = dc7__string.f_trim($o_uri['path'], '/'); 

		///h : return
		return($o_uri);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 * 
	 * @see http://phpjs.org/functions/parse_url/
	 *
	 */
	dc7__request.f_parseURI = function($s_uri, $s_part, $o_config)
		{
		///h : properties
		var $o_data = null;		///h :
		var $o_uri = null;		///h :
		
		///h :
		$s_part = (($s_part === undefined) ? null : $s_part);

		///h :
		$o_config = (($o_config === undefined) ? {} : $o_config);
		$o_config['b_clean'] = (($o_config['b_clean'] === undefined) ? true : $o_config['b_clean']);

		///h :
		$s_uri = (($s_uri === undefined) ? window.location.href : $s_uri);

		///h :
		if($o_config['b_clean'] === true)
			{
			$s_uri = dc7__request.f_getCleanURI($s_uri);
			}

		///h :
		$o_uri = dc7__request.f_parseURI__intern($s_uri);

		///h :
		$o_data = {};
		$o_data['s_uri'] = $s_uri;
		$o_data['s_scheme'] = (($o_uri['scheme'] === undefined) ? null : $o_uri['scheme']);
		$o_data['s_host'] = (($o_uri['host'] === undefined) ? null : $o_uri['host']);
		$o_data['a_host'] = dc7__var.f_initVar($o_data['s_host'], [], 'simple__array');	
		$o_data['i_host'] = $o_data['a_host'].length;
		$o_data['s_port'] = (($o_uri['port'] === undefined) ? null : $o_uri['port']);
		$o_data['s_user'] = (($o_uri['user'] === undefined) ? null : $o_uri['user']);
		$o_data['s_pass'] = (($o_uri['pass'] === undefined) ? null : $o_uri['pass']);
		$o_data['s_path'] = (($o_uri['path'] === undefined) ? null : $o_uri['path']);
		$o_data['s_path'] = dc7__var.f_initVar($o_data['s_path'], null, 'uri__part');
		$o_data['a_path'] = dc7__var.f_initVar($o_data['s_path'], [], 'simple__array');
		$o_data['i_path'] = (($o_data['a_path'] === null) ? 0 : $o_data['a_path'].length);
		$o_data['s_query'] = (($o_uri['query'] === undefined) ? null : $o_uri['query']);
		$o_data['o_query'] = dc7__string.f_getDataObject($o_data['s_query'], {'s_preset_id':'query'});
		$o_data['i_query'] = (($o_data['o_query'] === null) ? 0 : Object.keys($o_data['o_query']).length);
		$o_data['s_fragment'] = dc7__var.f_initVar($o_uri['fragment'], null);
		$o_data['a_fragment'] =  dc7__var.f_initVar($o_data['s_fragment'], null, 'simple__array');
		$o_data['i_fragment'] = (($o_data['a_fragment'] === null) ? 0 : $o_data['a_fragment'].length);
		$o_data['s_root'] = ($o_data['s_scheme'] + '://' + $o_data['s_host']);

		///h :
		$o_data['a_location'] = [$o_data['s_root'], $o_data['s_path'], (($o_data['s_port'] === null) ? '' : (':' + $o_data['s_port'])), $o_data['s_query'], $o_data['s_fragment']];
		$o_data['s_location'] = dc7__string.f_trim(dc7__array.f_implode($o_data['a_location'], '/'), '/');
		$o_data['s_subdomain'] = $o_data['a_host'][0];
		$o_data['s_domain'] = $o_data['a_host'][$o_data['i_host'] - 1];

		///h :
		$o_data = (($s_part !== null) ? $o_data[$s_part] : $o_data);

		///h : return
		return($o_data);
		}

		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__request.f_updateURI = function()
		{
		///h :
		var $s_uri_complete = null;		///h :
		var $s_fragment = null;			///h :
		
		///h : check change
		$s_uri_complete = location.href;
		if(dc7__request.__o_data['s_uri_complete'] === $s_uri_complete)
			{
			///h :
			return;
			}
		dc7__request.__o_data['s_uri_complete'] = $s_uri_complete;
		dc7__request.__o_data['o_uri'] = dc7__request.f_parseURI(location.href, null, {'b_clean':false});

		///h : trigger
		dc7.__o_data['dom__jqo_window'].trigger('dc7__request__changeuri');

		///h : 
		$s_fragment = dc7__request.__o_data['o_uri']['s_fragment'];

		///h 
		if(dc7__request.__o_data['s_fragment'] !== $s_fragment)
			{
			///h :
			dc7__request.__o_data['s_fragment'] = $s_fragment;

			///h : trigger
			dc7.__o_data['dom__jqo_window'].trigger('dc7__request__changefragment');
			}

		///h :
		dc7__request.__o_data['b_update_complete'] = true;
		}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__request.f_initSystem();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
 