//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__behavior__scroll($o_config){this.f_construct($o_config);}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__behavior__scroll.__o_data = null;				///h : stores class data
	dc7__behavior__scroll.__o_config = null;				///h : stores class config

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__behavior__scroll.f_initSystem = function()
		{
		dc7__behavior__scroll.f_init();
		}
	

	/////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class construct
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__behavior__scroll.prototype.f_construct = function($o_config)
		{
		this.f_init($o_config);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class static methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__scroll.f_init = function()
		{
		///h :
		dc7__behavior__scroll.f_initData();
		
		///h :
		dc7__behavior__scroll.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__scroll.f_initData = function()
		{
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__scroll.f_initContent = function()
		{
		///h : self init
		dc7__behavior.f_initBehaviorClass({'selector_behavior':'window', 's_behavior_id':'scroll', 'class_behavior':dc7__behavior__scroll});
		dc7__behavior.f_initBehaviorClass({'s_behavior_id':'scroll', 'class_behavior':dc7__behavior__scroll});
		dc7__behavior.f_updateElements();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class dynamic methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__scroll.prototype.f_init = function($o_config)
		{
		this.f_initData($o_config);
		this.f_initContent();
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__scroll.prototype.f_initData = function($o_config)
		{
		this.__o_data = {};
		this.__o_data['jqo_element'] = $o_config['jqo_element'];
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__scroll.prototype.f_initContent = function($o_config)
		{
		this.__o_data['jqo_element'].bind('scroll', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_updateContent(false);});
		dc7.__o_data['dom__jqo_document'].bind('click', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_updateContent(false);});		
		this.f_updateContent(true);
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__scroll.prototype.f_updateContent = function($b_init)
		{
		///h : properties
		var $i_scroll_x = -1;		///h : stores x
		var $i_scroll_y = -1;		///h : stores y
		var $s_scroll = null;		///h : stores scroll id

		///h : get current scroll values
		if(this.__o_data['jqo_element'][0] === dc7.__o_data['dom__jqo_window'][0])
			{
			$i_scroll_x = (window.pageXOffset || document.body.scrollLeft || document.documentElement.scrollLeft);
			$i_scroll_y = (window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop);
			}
		else
			{
			$i_scroll_x = this.__o_data['jqo_element'][0].scrollLeft;
			$i_scroll_y = this.__o_data['jqo_element'][0].scrollTop;
			}
		$s_scroll = ($i_scroll_x + '||' + $i_scroll_y);

		///h : check if scroll
		if($s_scroll !== this.__o_data['s_scroll'])
			{
			///h : scroll direction
			$s_scroll_direction_x = (($i_scroll_x < this.__o_data['i_scroll_x']) ? 'left' : 'right');
			$s_scroll_direction_y = (($i_scroll_y < this.__o_data['i_scroll_y']) ? 'up' : 'down');

			///h :
			if($s_scroll_direction_x !== this.__o_data['s_scroll_direction_x'])
				{
				this.__o_data['s_scroll_direction_x'] = $s_scroll_direction_x;
				}
			///h :
			if($s_scroll_direction_y !== this.__o_data['s_scroll_direction_y'])
				{
				this.__o_data['s_scroll_direction_y'] = $s_scroll_direction_y;
				}

			///h : update values
			this.__o_data['i_scroll_x'] = $i_scroll_x;
			this.__o_data['i_scroll_y'] = $i_scroll_y;
			this.__o_data['s_scroll'] = $s_scroll;

			///h : trigger
			if($b_init !== true)
				{ 
				///h : event : scroll
				$s_event_id = ('dc7__behavior__scroll__scroll');
				dc7__events.f_trigger(this.__o_data['jqo_element'], $s_event_id, [this.__o_data], [0.1, 0.8]);
		
				///h : event : scrollx
				$s_event_id = ('dc7__behavior__scroll__scroll' + this.__o_data['s_scroll_direction_x']);
				dc7__events.f_trigger(this.__o_data['jqo_element'], $s_event_id, [this.__o_data]);

				///h : event : scrolly
				$s_event_id = ('dc7__behavior__scroll__scroll' + this.__o_data['s_scroll_direction_y']);
				dc7__events.f_trigger(this.__o_data['jqo_element'], $s_event_id, [this.__o_data]);
				}
			}
		}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__behavior__scroll.f_initSystem();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
