//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__behavior__mouse($o_config){this.f_construct($o_config);}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__behavior__mouse.__o_data = null;				///h : stores class data
	dc7__behavior__mouse.__o_config = null;				///h : stores class config

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__behavior__mouse.f_initSystem = function()
		{
		dc7__behavior__mouse.f_init();
		}
	

	/////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class construct
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__behavior__mouse.prototype.f_construct = function($o_config)
		{
		this.f_init($o_config);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class static methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__mouse.f_init = function()
		{
		dc7__behavior__mouse.f_initData();
		dc7__behavior__mouse.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__mouse.f_initData = function()
		{
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__mouse.f_initContent = function()
		{
		///h : self init
		dc7__behavior.f_initBehaviorClass({'s_behavior_id':'mouse', 'class_behavior':dc7__behavior__mouse});
		dc7__behavior.f_updateElements();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class dynamic methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__mouse.prototype.f_init = function($o_config)
		{
		this.f_initData($o_config);
		this.f_initContent();
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__mouse.prototype.f_initData = function($o_config)
		{
		this.__o_data = {};
		this.__o_data['jqo_element'] = $o_config['jqo_element'];
		this.__o_data['jqo_clip'] = (($o_config['jqo_clip'] === undefined) ? this.__o_data['jqo_element'] : $o_config['jqo_clip']);
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__mouse.prototype.f_initContent = function($o_config)
		{
		dc7.__o_data['dom__jqo_document'].bind('mousemove mouseenter', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_updateMousePosition($o_event);});
		dc7.__o_data['dom__jqo_document'].bind('click', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_updateMouseButton($o_event);});
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__mouse.prototype.f_updateMouseButton = function($o_event)
		{
		///h : properties
		var $s_event_id = null;		///h :

		///h : event : scroll
		$s_event_id = ((this.__o_data['b_element_mouseover'] === true) ? 'dc7__behavior__mouse__' + $o_event.type + 'inside' : 'dc7__behavior__mouse__' + $o_event.type + 'outside');

		///h : trigger
		dc7__events.f_trigger(this.__o_data['jqo_element'], $s_event_id, [this.__o_data], false);		
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__mouse.prototype.f_updateMousePosition = function($o_event)
		{
		///h : properties
		var $i_mouse_x = -1;			///h : 
		var $i_mouse_y = -1;			///h : 
		var $s_mouse_position = -1;		///h : 
		var $b_element_mouseover = false;	///h : 

		///h : data
		$i_mouse_x = $o_event.clientX;
		$i_mouse_y = $o_event.clientY;
		$s_mouse_position = ($i_mouse_x + '||' + $i_mouse_y);

		///h : check if scroll
		if($s_mouse_position !== this.__o_data['s_mouse_position'])
			{
			///h : update values
			this.__o_data['i_mouse_x'] = $i_mouse_x;
			this.__o_data['i_mouse_y'] = $i_mouse_y;
			this.__o_data['s_mouse_position'] = $s_mouse_position;
			
			///h : mouseover
			if(this.__o_data['i_mouse_x'] < 0 || this.__o_data['i_mouse_y'] < 0)
				{
				///h : 
				$b_element_mouseover = false;
				}
			else
				{
				///h :
				this.__o_data['o_element_boundingclip'] = this.__o_data['jqo_clip'][0].getBoundingClientRect();
				$b_element_mouseover = (((this.__o_data['i_mouse_x'] >= this.__o_data['o_element_boundingclip'].left) && (this.__o_data['i_mouse_x'] <= this.__o_data['o_element_boundingclip'].right) && (this.__o_data['i_mouse_y'] >= this.__o_data['o_element_boundingclip'].top) && (this.__o_data['i_mouse_y'] <= this.__o_data['o_element_boundingclip'].bottom)) ? true : false);
				}
				
			///h : update
			if(this.__o_data['b_element_mouseover'] !== $b_element_mouseover)
				{
				///h : 
				this.__o_data['b_element_mouseover'] = $b_element_mouseover;

				///h : event : scroll
				$s_event_id = ((this.__o_data['b_element_mouseover'] === true) ? 'dc7__behavior__mouse__mouseenter' : 'dc7__behavior__mouse__mouseleave');
				dc7__events.f_trigger(this.__o_data['jqo_element'], $s_event_id, [this.__o_data], [0.8]);	
				}
			}
		}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__behavior__mouse.f_initSystem();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
