//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
  

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
function om3__admin(){this.f_construct();}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	om3__admin.__o_data = null;		///h :
   

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : construct
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	om3__admin.f_initSystem = function()
		{
		///h :
		om3__admin.f_init();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class construct
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : construct
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	om3__admin.prototype.f_construct = function()
		{
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__admin.f_init = function()
		{
		///: init config
		om3__frontend.f_initData();

		///: init config
		om3__frontend.f_initContent(); 
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__admin.f_initData = function()
		{
		om3__admin.__o_data = {};
		om3__admin.__o_data['class__s_version'] = '1.07.000548';    
		om3__admin.__o_data['b_available'] = jQuery('body').hasClass('neos-backend');
		om3__admin.__o_data['jqo_content'] = null;
		om3__admin.__o_data['jqo_structuretree'] = null;
		om3__admin.__o_data['b_structuretree_init'] = false;
		om3__admin.__o_data['jqo_inspector'] = null;
		om3__admin.__o_data['jqo_inspector_update'] = null;
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	om3__admin.f_init = function()
		{
		///h : init data
		om3__admin.f_initData();

		///h: init display
		om3__admin.f_initContent();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	om3__admin.f_initContent = function()
		{
		if(om3__admin.__o_data['b_available'] === true)
			{
			///h : engine
			om3__admin.f_initEngine();

			///h : class var
			om3__admin.f_initClassVersion();
			}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	om3__admin.f_initClassVersion = function($s_event_id)
		{
		///h :
		jQuery('body').append('<div class=\'om3__admin__info\'><div class=\'om3__admin__info_content\'><span>om3 ' + om3__frontend.__o_data['class__s_version'] + '</span></div></div>');
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	om3__admin.f_initEngine = function()
		{
		///h : load
		dc7.__o_data['dom__jqo_window'].bind('om3__admin__neoscontentmoduleloaded', {}, function(){om3__admin.f_updateAdmin();});
		///h : event - check selected node
		document.addEventListener('Neos.PageLoaded', function($o_event){om3__admin.f_handleNeosEvents($o_event)}, false);
		document.addEventListener('Neos.PreviewModeActivated', function($o_event){om3__admin.f_handleNeosEvents($o_event)}, false);
		document.addEventListener('Neos.PreviewModeDeactivated', function($o_event){om3__admin.f_handleNeosEvents($o_event)}, false);
		document.addEventListener('Neos.ContentModuleLoaded', function($o_event){om3__admin.f_handleNeosEvents($o_event)}, false);
		document.addEventListener('Neos.NodeCreated', function($o_event){om3__admin.f_handleNeosEvents($o_event)}, false);
		document.addEventListener('Neos.NodeRemoved', function($o_event){om3__admin.f_handleNeosEvents($o_event)}, false);
		document.addEventListener('Neos.LayoutChanged', function($o_event){om3__admin.f_handleNeosEvents($o_event)}, false);
		document.addEventListener('Neos.NavigatePanelOpened', function($o_event){om3__admin.f_handleNeosEvents($o_event)}, false);
		document.addEventListener('Neos.NavigatePanelClosed', function($o_event){om3__admin.f_handleNeosEvents($o_event)}, false);
		document.addEventListener('Neos.InspectorPanelOpened', function($o_event){om3__admin.f_handleNeosEvents($o_event)}, false);
		document.addEventListener('Neos.InspectorPanelClosed', function($o_event){om3__admin.f_handleNeosEvents($o_event)}, false);
		document.addEventListener('Neos.EditPreviewPanelOpened', function($o_event){om3__admin.f_handleNeosEvents($o_event)}, false);
		document.addEventListener('Neos.EditPreviewPanelClosed', function($o_event){om3__admin.f_handleNeosEvents($o_event)}, false);
		document.addEventListener('Neos.MenuPanelOpened', function($o_event){om3__admin.f_handleNeosEvents($o_event)}, false);
		document.addEventListener('Neos.MenuPanelClosed', function($o_event){om3__admin.f_handleNeosEvents($o_event)}, false);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	om3__admin.f_handleNeosEvents = function($o_event)
		{
		///h :
		$s_event_id = ('om3__admin__' + $o_event['type'].toLowerCase().replace('.', ''));

		///h : 
		dc7.__o_data['dom__jqo_window'].trigger($s_event_id, [$o_event]);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	om3__admin.f_updateAdmin = function()
		{
		///h : reset all tree elements
		om3__admin.__o_data['jqo_content'] = jQuery('.content');
		om3__admin.__o_data['jqo_structuretree'] = jQuery('#neos-context-structure-tree > ul > li > .neos-dynatree-node');
		om3__admin.__o_data['jqo_inspector'] = jQuery('#neos-inspector');
		om3__admin.__o_data['jqo_inspector_update'] = jQuery('#neos-inspector .neos-inspector-apply');
		om3__admin.__o_data['jqo_content_links'] = om3__admin.__o_data['jqo_content'].find('a'); 
		om3__admin.__o_data['i_content_links'] = om3__admin.__o_data['jqo_content_links'].length;

		///h : link
		om3__admin.f_updateContentLinks();

		///h : update structure
		om3__admin.f_closeStructureTree();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	om3__admin.f_closeStructureTree = function()
		{
		///h :
		$jqo_elements = om3__admin.__o_data['jqo_structuretree'].find('.neos-dynatree-expander');
		if($jqo_elements.length == 0)
			{ 
			///h :
			//TweenMax.delayedCall(0.2, function(){om3__admin.f_closeStructureTree();});
			}
		else
			{
			///h :
			//$jqo_elements.css({'border':'2px solid red'});
			}
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	om3__admin.f_updateContentLinks = function()
		{
		///h :
		var $jqo_element = null;	///h :
		var $i_loop = null;		///h :

		///h : parse behavior);
		for($i_loop = 0; $i_loop < om3__admin.__o_data['i_content_links']; $i_loop ++)
			{
			///h :
			$jqo_element = jQuery(om3__admin.__o_data['jqo_content_links'][$i_loop]);
			
			///h :
			if(dc7__dom.f_getVar($jqo_element, 'om3__admin__b_linkreset') !== true)
				{
				///h : remove click
				$jqo_element.bind('click', {}, function($o_event){$o_event.preventDefault(); return;});
				$jqo_element.removeAttr('href');

				///h :
				dc7__dom.f_setVar($jqo_element, 'om3__admin__b_linkreset', true);
				}
			}

		///h : no modal call
		jQuery('.dc7__behavior__acall').unbind('click mouseenter mouseout');
		}


///*    private functions ////////////////////////////////////////////////////////////////////////////////////

///*    class init ///////////////////////////////////////////////////////////////////////////////////////////
jQuery(document).bind('ready', {}, function(){om3__admin.f_initSystem();});

/*
--------------------------------------------------------------------------------------------------------------
description:
--------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------
*/