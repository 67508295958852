//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__array(){this.f_construct();}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__array.__o_data = null;				///h : stores class data
	dc7__array.__o_config = null;				///h : stores class config


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__array.f_initSystem = function()
		{
		dc7__array.f_init();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init
	/**
	 * init
	 * init class calling all default functions like initenvironment, initdata
	 * initcontent.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__array.f_init = function()
		{
		///: init config
		dc7__array.f_initData();

		///: init config
		dc7__array.f_initContent();
		} 


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init data
	 * used to init all data later on used in the class context like
	 * configuration arrays etc.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__array.f_initData = function()
		{
		///h :
		dc7__array.__o_data = {};
		dc7__array.__o_data['a_filter__empty'] = ['null', undefined, null, ''];
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__array.f_initContent = function()
		{
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__array.f_inArray = function($a_data, $o_element)
		{
		///h :
		return(indexOf($a_data, $o_element) === -1);
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__array.f_getIndex = function($a_data, $o_element, $o_config)
		{
		///h : 
		var $i_index = -1;		///h :
		var $i_data = -1;		///h : stores loop index
		var $i_loop = -1;		///h : stores loop elements
		var $b_index = false;		///h :

		///h :
		$o_config = dc7__var.f_initVar($o_config, {});
		$o_config['b_jquery'] = dc7__var.f_initVar($o_config['b_jquery'], false);

		///h :
		$i_data = $a_data.length;
		for($i_loop = ($i_data - 1); $i_loop > -1; $i_loop--)
			{
			///h : jquery object
			if($o_config['b_jquery'] === true)
				{
				if($a_data[$i_loop][0] === $o_element[0])
					{
					$i_index = $i_loop;
					break;
					}
				}
			///h : normal object
			else
				{
				if($a_data[$i_loop] === $o_element)
					{
					$i_index = $i_loop;
					break;
					}
				}
			}

		///h :
		return($i_index);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__array.f_remove = function($a_data, $o_element, $o_config)
		{
		///h : 
		var $i_index = -1;		///h :

		///h :
		$o_config = dc7__var.f_initVar($o_config, {});
		$o_config['b_jquery'] = dc7__var.f_initVar($o_config['b_jquery'], false);

		///h :
		$i_index = dc7__array.f_getIndex($a_data, $o_element, $o_config);

		///h :
		if($i_index !== -1)
			{
			$a_data.splice($i_index, 1);
			}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__array.f_each = function($a_data, $f_each, $o_config)
		{
		///h :
		var $i_data = -1;						///h :
		var $i_loop = -1;						///h :

		///h : data
		$i_data = $a_data.length;					///h :
		$b_is_jquery = ($a_data instanceof jQuery);			///h :
		$o_config = (($o_config === undefined) ? {} : $o_config);	///h :

		///h :
		if($i_data > 0)
			{
			///h :
			for($i_loop = 0; $i_loop < $i_data; $i_loop++)
				{
				///h : jquery array
				if($b_is_jquery === true)
					{
					///h :
					$o_data = jQuery($a_data[$i_loop]);
					}
				///h : normal array
				else
					{
					$o_data = $a_data[$i_loop];
					}

				///h : each function
				$o_config['i_index'] = $i_loop;
				$f_each($o_data, $o_config);
				}
			}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__array.f_filter__empty = function($o_var_value)
		{
		///h :
		var $b_valid = false;
		
		///h :
		if(dc7__array.__o_data === null)
			{
			///h :
			dc7__array.f_initData();
			}

		///h :
		if(dc7__array.__o_data['a_filter__empty'].indexOf($o_var_value) < 0)
			{
			///h :
			$b_valid = true;
			}

		///h :
		return($b_valid);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__array.f_filter = function($a_data, $f_filter)
		{
		///h :
		$f_filter = (($f_filter === undefined) ? dc7__array.f_filter__empty : $f_filter);

		///h : properties
		$a_data = $a_data.filter($f_filter);

		///h : return
		return($a_data);
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__array.f_implode = function($a_data, $s_divider, $o_config)
		{
		///h :
		$s_divider = (($s_divider === undefined) ? '' : $s_divider);
		$o_config = (($o_config === undefined) ? {} : $o_config);
		$o_config['b_filter'] = (($o_config['b_filter'] === undefined) ? true : $o_config['b_filter']);
		$o_config['f_filter'] = (($o_config['f_filter'] === undefined) ? dc7__array.f_filter__empty : $o_config['f_filter']);

		///h :
		if($o_config['b_filter'] === true)
			{
			$a_data = dc7__array.f_filter($a_data, $o_config['f_filter']);
			}
		$s_data = $a_data.join($s_divider);

		///h : return
		return($s_data);
		}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__array.f_initSystem();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
