//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic Ã¢â‚¬â€� massimo cardascia Ã¢â‚¬â€� www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
 
///////////////////////////////////////////////init///////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
function project__timeline__shadow(){this.f_construct();}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class construct
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : construct
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	project__timeline__shadow.prototype.f_construct = function()
		{
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__timeline__shadow.f_getTimeline = function($o_config)
		{
		///h :
		var $o_data = null;		///h :
		var $timeline_data = null;	///h :
		var $tween_data = null;		///h :

		/// d2ff00 - animation
		/// 20ac9d - link

		///h :
		$o_config['jqo_element'] = $o_config['jqo_element'];
		$o_config['jqo_image'] = $o_config['jqo_element'].find('_image_box');
		$o_config['jqo_image_left'] = $o_config['jqo_image'].find('._image_box_left');
		$o_config['jqo_image'] = $o_config['jqo_element'].find('.permanent-image-permimage');
		$o_config['jqo_image_figure'] = $o_config['jqo_image'].find('figure');
		$o_config['color_red'] = '#FF00FF';


		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : colors and init

		///h :
		TweenMax.set($o_config['jqo_image_left'], {'y':'100.00%', 'width':'100.00%', 'height':'100.00%', 'background-color':$o_config['color_red']});


		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : timeline

		///h :
		$o_data = {};
		$o_data['paused'] = true;
		$o_data['repeat'] = 0;
		$o_data['delay'] = 0;

		///h :
		$timeline_data = new TimelineMax($o_data);


		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : timeline - image

		///h :
		$timeline_data.add('image', 0.30);
 
		///h : 
		$tween_data = TweenMax.to($o_config['jqo_image_left'], 0.01, {'y':'0.00%', 'height':'100%', 'ease':'Expo.easeInOut', 'delay':0});
		$timeline_data.add($tween_data, 'image+=0.00');

		///h : 
		$tween_data = TweenMax.to($o_config['jqo_image_left'], 0.62, {'height':'0%', 'y':'100.00%', 'ease':'Expo.easeInOut', 'delay':0});
		$timeline_data.add($tween_data, 'image+=0.20');

		///h :
		return($timeline_data); 
		}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
