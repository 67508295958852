//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__behavior__gmaps($o_config){this.f_construct($o_config);}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__behavior__gmaps.__o_data = null;				///h : stores class data
	dc7__behavior__gmaps.__o_config = null;				///h : stores class config

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__behavior__gmaps.f_initSystem = function()
		{
		dc7__behavior__gmaps.f_init();
		}
	

	/////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__behavior__gmaps.prototype.f_construct = function($o_config)
		{
		this.f_init($o_config);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class static methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__gmaps.f_init = function()
		{
		///h :
		dc7__behavior__gmaps.f_initData();
		
		///h :
		dc7__behavior__gmaps.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__gmaps.f_initData = function()
		{
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__gmaps.f_initContent = function()
		{
		///h : self init
		dc7__behavior.f_initBehaviorClass({'s_behavior_id':'gmaps', 'class_behavior':dc7__behavior__gmaps});
		dc7__behavior.f_updateElements();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__gmaps.prototype.f_init = function($o_config)
		{
		///h :
		this.f_initData($o_config);
		
		///h :
		this.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__gmaps.prototype.f_initData = function($o_config)
		{
		///h :
		this.__o_data = {};
		this.__o_data['jqo_element'] = $o_config['jqo_element'];
		}

	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__gmaps.prototype.f_updateProtection = function($b_active)
		{
		///h :
		$s_pointerevents = (($b_active === true) ? 'all' : 'none');
		this.__o_data['jqo_protection'].css({'pointer-events':$s_pointerevents});
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__gmaps.prototype.f_initContent = function($o_config)
		{
		///h :
		this.__o_data['jqo_element'].append('<div class=\'dc7__behavior__gmaps_protection\' style=\'width:100%; height:100%; position:absolute; z-index:10; background-color:transparent; position:absolute; left:0px; top:0px; cursor:pointer\'></div>');
		this.__o_data['jqo_protection'] = this.__o_data['jqo_element'].find('.dc7__behavior__gmaps_protection');

		///h : 
		this.__o_data['jqo_protection'].bind('click touchstart', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_updateProtection(false);});
		this.__o_data['jqo_element'].bind('mouseleave', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_updateProtection(true);});
		}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__behavior__gmaps.f_initSystem();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
