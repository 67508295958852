/*
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
code:		plustic — massimo cardascia — www.plustic.de
code language:	js 5
code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
*/

///*	class definition /////////////////////////////////////////////////////////////////////////////////////
function project__behavior__menu($o_config){this.f_construct($o_config);}
 
///*    class properties /////////////////////////////////////////////////////////////////////////////////////

///*    class system init ////////////////////////////////////////////////////////////////////////////////////
	project__behavior__menu.f_initSystem = function()
		{
		project__behavior__menu.f_init();
		}

///*    class constructor ////////////////////////////////////////////////////////////////////////////////////
	project__behavior__menu.prototype.f_construct = function($o_config)
		{
		///h : init
		this.f_init($o_config);
		}

///*    public static functions //////////////////////////////////////////////////////////////////////////////

///*    public functions /////////////////////////////////////////////////////////////////////////////////////

///*    pivate static functions //////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class static methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__menu.f_init = function()
		{
		///h :
		project__behavior__menu.f_initData();
		
		///h :
		project__behavior__menu.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__menu.f_initData = function()
		{
		
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__menu.f_initContent = function()
		{
		///h : init behavior
		dc7__behavior.f_initBehaviorClass({'s_behavior_id':'project__behavior__menu', 'class_behavior':project__behavior__menu, 'selector_behavior':'.om3__segment__menu'});
		dc7__behavior.f_updateElements(); 
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__menu.prototype.f_init = function($o_config)
		{
		///h : update important values
		this.f_initData($o_config);

		///h : init engine
		this.f_initContent();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__menu.prototype.f_initData = function($o_config)
		{
		///h : data 
		this['__o_data'] = {}; 
		this['__o_data']['o_config'] = $o_config;
		this['__o_data']['jqo_element'] = $o_config['jqo_element'];
		this['__o_data']['jqo_burger'] = jQuery('.om3__segment__menu_burger');
		this['__o_data']['jqo_close'] = jQuery('.om3__segment__menu_navigation_close');
		this['__o_data']['b_navigation'] = false;
		this['__o_data']['jqo_footeraddress'] = jQuery('.__s_segment_id__footeraddress');
		this['__o_data']['jqo_address'] = jQuery('.om3__segment__menu_navigation_elements_address');
		
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__menu.prototype.f_initContent = function()
		{
		///h :
		dc7.__o_data['dom__jqo_window'].on('resize scroll', {'scope_data':this}, function($o_event){$o_event.data['scope_data'].f_updateShrink();});	
		this.f_updateShrink();

		///h :
		this['__o_data']['jqo_burger'].on('mousedown', {'scope_data':this}, function($o_event){$o_event.data['scope_data'].f_showNavigation(true);});
		this['__o_data']['jqo_close'].on('mousedown', {'scope_data':this}, function($o_event){$o_event.data['scope_data'].f_showNavigation(false);});
		this.f_showNavigation(false);
		
		///h : copy
		this['__o_data']['jqo_address'].html(this['__o_data']['jqo_footeraddress'].html());
		}
		

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__menu.prototype.f_showNavigation = function($b_visible)
		{ 
		///h :
		$b_visible = dc7__var.f_initVar($b_visible, !this['__o_data']['b_navigation']);
		this['__o_data']['b_navigation'] = $b_visible;

		///h :
		dc7__dom.f_setClassVar(this['__o_data']['jqo_element'], '_navigation', $b_visible);
		dc7__dom.f_setClassVar(jQuery('html'), '_navigation', $b_visible);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__menu.prototype.f_updateShrink = function()
		{
		///h :
		var $i_y = null;		///h :
		var $b_shrinked = null;		///h :

		///h :
		$i_y = window.pageYOffset;
		$b_shrinked = (($i_y <= 1) ? false : true);
		
		///h :
		dc7__dom.f_setClassVar(this['__o_data']['jqo_element'], '_shrinked', $b_shrinked);
		}


///*    init class ///////////////////////////////////////////////////////////////////////////////////////////
jQuery(document).bind('ready', function(){project__behavior__menu.f_initSystem();});

/*
--------------------------------------------------------------------------------------------------------------
description:
--------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------
*/