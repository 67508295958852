
/*
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
code:		plustic — massimo cardascia — www.plustic.de
code language:	js 5
code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
*/ 

///*	class definition /////////////////////////////////////////////////////////////////////////////////////
function om3__behavior__sliderextended($o_config){this.f_construct($o_config);}

///*    class properties /////////////////////////////////////////////////////////////////////////////////////

///*    class system init ////////////////////////////////////////////////////////////////////////////////////
	om3__behavior__sliderextended.f_initSystem = function()
		{
		om3__behavior__sliderextended.f_init();
		}
 
///*    class constructor ////////////////////////////////////////////////////////////////////////////////////
	om3__behavior__sliderextended.prototype.f_construct = function($o_config)
		{
		///h : init
		this.f_init($o_config);
		}

///*    public static functions //////////////////////////////////////////////////////////////////////////////

///*    public functions /////////////////////////////////////////////////////////////////////////////////////

///*    pivate static functions //////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class static methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__sliderextended.f_init = function()
		{
		///h :
		om3__behavior__sliderextended.f_initData();
		
		///h :
		om3__behavior__sliderextended.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__sliderextended.f_initData = function()
		{
		///h :
		om3__behavior__sliderextended.__o_data = {};
		
		///h :
		om3__behavior__sliderextended.__o_data['admin__b_available'] = jQuery('body').hasClass('neos-backend');
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__sliderextended.f_initContent = function()
		{
		///h _
		var $jqo_video = null;
		var $i_video = null;
		var $i_loop = null;	
	
		///h :
		if(om3__behavior__sliderextended.__o_data['admin__b_available'] === true)
			{
			return;
			}

		///h : init behavior
		dc7__behavior.f_initBehaviorClass({'s_behavior_id':'slider', 'class_behavior':om3__behavior__sliderextended, 'selector_behavior':'.om3__segment__sliderextended', 's_dom_config':'om3__behavior__sliderextended'});
		dc7__behavior.f_updateElements();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__sliderextended.prototype.f_init = function($o_config)
		{
		///h : update important values
		this.f_initData($o_config); 

		///h : init engine
		this.f_initContent(); 
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__sliderextended.prototype.f_initData = function($o_config)
		{
		///h : data 
		this.__o_data = {};
		this.__o_data['o_config'] = $o_config;
		this.__o_data['jqo_element'] = $o_config['jqo_element'];
		this.__o_data['jqo_slides'] = this.__o_data['jqo_element'].find('.om3__segment__sliderextended_slide');
		this.__o_data['i_slides'] = this.__o_data['jqo_slides'].length;
		this.__o_data['i_slidesonscreen'] = this.__o_data['jqo_element'].data('slidesonscreen');
		this.__o_data['jqo_numbers'] = null;
		this.__o_data['b_autoplay'] = ((this.__o_data['jqo_element'].data('autoplay') === true) ? true : false);
		this.__o_data['b_arrows'] = ((this.__o_data['jqo_element'].data('arrows') === true) ? true : false);
		this.__o_data['ms_pause'] = parseFloat(this.__o_data['jqo_element'].data('pause'));
		this.__o_data['ms_duration'] = parseFloat(this.__o_data['jqo_element'].data('duration'));
		this.__o_data['i_index'] = 0;
		this.__o_data['s_bullets'] = dc7__dom.f_getClassVar(this.__o_data['jqo_element'], '_bullets');
		}
 

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__sliderextended.prototype.f_showImage = function($i_index)
		{
		///h :
		this.__o_data['jqo_element'].trigger('to.owl.carousel' ,[$i_index]);
		}
		
		//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__sliderextended.prototype.f_updateNumbers__delayed = function()
		{
		///h :
		TweenMax.delayedCall(0.40, function(){this.f_updateNumbers(null, null)}, null, this);
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__sliderextended.prototype.f_updateNumbers = function($i_index, $b_animation)
		{
		///h :
		var $num_duration = null;		///h :
		var $jqo_domactive = null;		///h :
		var $num_y = null;			///h :

		///h :
		$i_index = (($i_index === null) ? this.__o_data['i_index'] : $i_index);
		this.__o_data['i_index'] = $i_index;
		$jqo_domactive = jQuery(this.__o_data['jqo_numberselements'][$i_index]);
		$num_y = ($jqo_domactive.position().top + 15);

		///h :
		this.__o_data['jqo_line'].css({'top':$num_y});
		
		///h : video
		if(this.__o_data['jqo_slides'].find('video').length > 0)
			{
			///h :
			this.__o_data['jqo_slides'].find('video').each(function(){jQuery(this)[0].pause();});
			$jqo_element = jQuery(this.__o_data['jqo_slides'][$i_index]).find('video');
			
			///h :
			if($jqo_element.length === 1)
				{
				///h :
				$jqo_element[0].play();
				}
			}
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__sliderextended.prototype.f_initMaskingFX = function()
		{
		///h :
		var $i_loop = null;			///h :
		var $jqo_element = null;		///h :
		var $html_data = null;			///h :
			
		///h :
		for($i_loop = 0; $i_loop < this.__o_data['i_slides']; $i_loop++)
			{
			///h ;
			$jqo_element = jQuery(this.__o_data['jqo_slides'][$i_loop]);
			
			///h :
			$html_data = '';
			$html_data += ('<div class=\'om3__segment__sliderextended_slide_mask\'></div>');
			
			///h :
			$jqo_element.append($html_data);
			}
		}
 
	
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__sliderextended.prototype.f_initContent = function()
		{
		///h :
		var $s_index = null;		///h :
		var $i_loop = null;		///h :
		var $jqo_dom = null;		///h :
		var $i_video = null;		///h :
		var $jqo_video = null;		///h :
		
		///h :
		$jqo_video = this.__o_data['jqo_element'].find('video');
		$i_video = $jqo_video.length;
		for($i_loop = 0; $i_loop < $i_video; $i_loop++)
			{ 
			///h 
			$jqo_dom = jQuery($jqo_video[$i_loop]);
			$jqo_dom[0].pause();
			}


		///h : deactivate in frontend		
		if(om3__behavior__sliderextended.__o_data['admin__b_available'] === true)
			{
			return;
			} 

		///h : 
		this.__o_data['jqo_element'].addClass('owl-carousel');

		///h : init owl initC
		$o_config = {};
		$o_config['items'] = this.__o_data['i_slidesonscreen'];
		$o_config['loop'] = ((this.__o_data['i_slides'] < 2) ? false : true);
		$o_config['nav'] = this.__o_data['b_arrows'];
		$o_config['dots'] = true;
		$o_config['autoplay'] = this.__o_data['b_autoplay'];
		$o_config['autoplayTimeout'] = this.__o_data['ms_pause'];
		$o_config['autoplaySpeed'] = this.__o_data['ms_duration'];
		$o_config['responsive'] = {0:{'items':1}, 600:{'items':this.__o_data['i_slidesonscreen']}};
		$o_config['itemElement'] = 'nav';
		
		///h : 
		this.__o_data['jqo_element'].owlCarousel($o_config);
		this.__o_data['o_owl'] = this.__o_data['jqo_element'].data('owlCarousel');
 

		///h :	
		this.__o_data['jqo_element'].find('.owl-prev').html('<i class="_mousefollow fa fa-chevron-circle-left" aria-hidden="true"></i>');
		this.__o_data['jqo_element'].find('.owl-next').html('<i class="_mousefollow fa fa-chevron-circle-right" aria-hidden="true"></i>');
 

		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : bullets
		 
		///h :
		this.__o_data['jqo_element'].find('.owl-dots').addClass('om3__segment__sliderextended__bullets');


		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : videos

		///h :
		for($i_loop = 0; $i_loop < this.__o_data['i_slides']; $i_loop++)
			{
			///h ;
			$jqo_element = jQuery(this.__o_data['jqo_slides'][$i_loop]);
			$jqo_video = $jqo_element.find('video');
			
			///h :
			if($jqo_video.length >= 1)
				{
				///h :
				$jqo_video[0].pause();
				}
			}

		
		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : numbers

		///h :
		$html_data = '';
		$html_data += ('<div class=\'om3__segment__sliderextended__numbers\'>');

		///h :
		$html_data += ('<div class=\'om3__segment__sliderextended__numbers__line\'></div>');

		///h :
		for($i_loop = 0; $i_loop < this.__o_data['i_slides']; $i_loop++)
			{
			///h ;
			$s_index = ('00' + ($i_loop + 1)).substr(-2);
			$html_data += ('<div class=\'om3__segment__sliderextended__numbers__element _mousefollow\' data-_mousefollowtext=\'' +  $s_index + '\'><h6>' + $s_index + '</h6></div><br />');
			}

		$html_data += ('</div>');

		///h : 
		this.__o_data['jqo_element'].append($html_data);
		this.__o_data['jqo_numbers'] = this.__o_data['jqo_element'].find('.om3__segment__sliderextended__numbers');
		this.__o_data['jqo_numberselements'] = this.__o_data['jqo_numbers'].find('.om3__segment__sliderextended__numbers__element');
		this.__o_data['jqo_line'] = jQuery('.om3__segment__sliderextended__numbers__line');
	
		///h :
		for($i_loop = 0; $i_loop < this.__o_data['i_slides']; $i_loop++)
			{ 
			///h 
			$jqo_dom = jQuery(this.__o_data['jqo_numberselements'][$i_loop]);
			$jqo_dom.on('mousedown', {'i_index':$i_loop, 'scope_data':this}, function($event_data){$event_data.data['scope_data'].f_showImage($event_data.data['i_index']);});
			}


		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : resize

		///h :
			// fix for ie11
            if (window.navigator.userAgent.indexOf("Trident/7.0") > -1){
                var event = document.createEvent("Event");
                event.initEvent("resize", false, true); // TODO deprecated js
                window.dispatchEvent(event);
			}else {
                window.dispatchEvent(new Event('resize'));
			}




		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : update numbers

		///h :
		if(this.__o_data['s_bullets'] === 'number')
			{
			///h : 
			this.__o_data['jqo_element'].on('changed.owl.carousel', {'scope_data':this}, function($event_data){$event_data.data['scope_data'].f_updateNumbers($event_data['page']['index'], true);});
			jQuery(window).on('load resize', {'scope_data':this}, function($event_data){$event_data.data['scope_data'].f_updateNumbers__delayed();});
			this.f_updateNumbers(0, false);
			}
		}


///*    init class ///////////////////////////////////////////////////////////////////////////////////////////
jQuery(document).bind('ready', function(){om3__behavior__sliderextended.f_initSystem();});
 