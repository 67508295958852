/*
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
code:		plustic — massimo cardascia — www.plustic.de
code language:	js 5
code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
*/

///*	class definition /////////////////////////////////////////////////////////////////////////////////////
function om3__behavior__menu($o_config){this.f_construct($o_config);}

///*    class properties /////////////////////////////////////////////////////////////////////////////////////

///*    class system init ////////////////////////////////////////////////////////////////////////////////////
	om3__behavior__menu.f_initSystem = function()
		{
		om3__behavior__menu.f_init();
		}

///*    class constructor ////////////////////////////////////////////////////////////////////////////////////
	om3__behavior__menu.prototype.f_construct = function($o_config)
		{
		///h : init
		this.f_init($o_config);
		}

///*    public static functions //////////////////////////////////////////////////////////////////////////////

///*    public functions /////////////////////////////////////////////////////////////////////////////////////

///*    pivate static functions //////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class static methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__menu.f_init = function()
		{
		///h :
	//	om3__behavior__menu.f_initData();
		
		///h :
	//	om3__behavior__menu.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__menu.f_initData = function()
		{
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__menu.f_initContent = function()
		{
		///h : init behavior
		dc7__behavior.f_initBehaviorClass({'s_behavior_id':'menu', 'class_behavior':om3__behavior__menu, 'selector_behavior':'.content_menu', 's_dom_config':'om3__behavior__menu'});
		dc7__behavior.f_initBehaviorClass({'s_behavior_id':'mouse', 'class_behavior':dc7__behavior__mouse, 'selector_behavior':'.content_menu'});
		dc7__behavior.f_updateElements();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__menu.prototype.f_init = function($o_config)
		{
		///h : update important values
		this.f_initData($o_config);

		///h : init engine
		this.f_initContent();
		}
	

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__menu.prototype.f_updateMenuVisibility = function()
		{
		///h : 
		if(dc7__grid.__o_data['s_breakpoint_id'] === 'sma')
			{
			return;
			}

		///h : 
		if((this.__o_data['s_layout_type'] === 'menu2') && (dc7__grid.__o_data['s_breakpoint_id'] === 'tab'))
			{
			return;
			}

		///h : 
		var $i_window_y = -1;
		var $b_menu_visible = false;
		
		///h : 
		$b_menu_visible = false;
		if($i_window_y < 1)
			{
			$b_menu_visible = true;
			}
		this.f_showMenu($b_menu_visible, false);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__menu.prototype.f_showMenu = function($b_menu_visible, $b_animation)
		{
		///h : properties
		var $i_y = null;		///h :
		var $i_y__hidden = null;	///h :
		var $num_duration = -1;		///h :
		var $s_display = null;		///h :

		///h :
		$b_menu_visible = (($b_menu_visible === undefined) ? !this.__o_data['b_menu_visible'] : $b_menu_visible);
		if($b_menu_visible === this.__o_data['b_menu_visible'])
			{
			return; 
			}
		this.__o_data['b_menu_visible'] = $b_menu_visible;

		///h : 
		if(dc7__grid.__o_data['s_breakpoint_id'] === 'sma')
			{
			$i_y = 0;
			$s_display = ((this.__o_data['b_menu_visible'] === true) ? 'block' : 'none');
			}
		///h : 
		else if((dc7__grid.__o_data['s_breakpoint_id'] === 'tab') && (this.__o_data['s_layout_type'] === 'menu2'))
			{
			$i_y = 0;
			$s_display = ((this.__o_data['b_menu_visible'] === true) ? 'block' : 'none');
			}  
		else
			{ 
			///h: 
			$i_y = ((this.__o_data['b_menu_visible'] === true) ? 0 : -81);
			$s_display = 'block';
			}
		
		///h : 
		jQuery('.content_menu .nav-pills').css({'display':$s_display});
		
		$i_y__hidden = -82;
		if(this.__o_data['s_layout_type'] === 'menu2')
			{
			$i_y__hidden = -(82 + 80);
			}
		
		///h : 
		$num_duration = ((dc7__grid.__o_data['s_breakpoint_id'] === 'sma') ? 0 : 0.8);
		$num_duration = (((dc7__grid.__o_data['s_breakpoint_id'] === 'tab') && (dc7__grid.__o_data['s_breakpoint_id'] === 'tab')) ? 0 : $num_duration);
		$num_duration = (($b_animation === false) ? 0 : $num_duration);

		$i_y = ((this.__o_data['b_menu_visible'] === true) ? 0 : $i_y__hidden);
		$i_y = ((dc7__grid.__o_data['s_breakpoint_id'] === 'sma') ? 0 : $i_y);
		$i_y = (((dc7__grid.__o_data['s_breakpoint_id'] === 'tab') && (this.__o_data['s_layout_type'] === 'menu2')) ? 0 : $i_y);

		//dc7__dom.f_setClassVar(this.__o_data['jqo_element'], '__bs_active', $b_menu_visible);
		TweenMax.to(this.__o_data['jqo_element'], $num_duration, {'y':$i_y, 'ease':'Expo.easeOut', 'overwrite':'auto', 'delay':0});
		}	


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__menu.prototype.f_updateBreakpoint = function()
		{
		///h :
		if(dc7__grid.__o_data['s_breakpoint_id'] === 'sma')
			{
			///h :
			this.f_showMenu(false, false);
			}
		///h :
		else if((this.__o_data['s_layout_type'] === 'menu2') && (dc7__grid.__o_data['s_breakpoint_id'] === 'tab'))
			{
			///h :
			this.f_showMenu(false, false);
			}
		///h :
		else
			{
			///h :
			this.f_updateMenuVisibility();
			}
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__menu.prototype.f_checkMenuVisibility = function($s_id)
		{
		///h : 
		if(dc7__grid.__o_data['s_breakpoint_id'] === 'sma')
			{
			///h :
			return;
			}
	
		///h :
		if((this.__o_data['s_layout_type'] === 'menu2') && (dc7__grid.__o_data['s_breakpoint_id'] === 'tab'))
			{
			///h :
			return;
			}

		///h : 
		$b_menu_visible = true;

		///h : scrolldown
		if($s_id === 'scrolldown')
			{
			///h : special case mouse near menu
			if(dc7.__o_data['window__i_mousey'] >= 100)
				{
				$b_menu_visible = false;
				}
			}

		///h : scrolldown
		if($s_id === 'mousemove')
			{
			///h :
			$b_menu_visible = false;

			///h : special case mouse near menu
			if(dc7.__o_data['window__i_mousey'] < 100)
				{
				///h :
				$b_menu_visible = true;
				}
			///h :
			else
				{
				///h :
				return;
				}
			}

		///h : show or hide menu
		this.f_showMenu($b_menu_visible);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__menu.prototype.f_initContent = function()
		{
		///h : layouttype
		dc7__dom.f_setClassVar(this.__o_data['jqo_element'], '__s_layout_type', this.__o_data['s_layout_type']);

		///h : 
		dc7.__o_data['dom__jqo_window'].bind('dc7__behavior__scroll__scrolldown', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_checkMenuVisibility('scrolldown');});
		dc7.__o_data['dom__jqo_window'].bind('dc7__behavior__scroll__scrollup', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_checkMenuVisibility('scrollup');});
		dc7.__o_data['dom__jqo_window'].bind('mousemove', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_checkMenuVisibility('mousemove');});
		dc7.__o_data['dom__jqo_window'].bind('dc7__grid__breakpointchange', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_updateBreakpoint();});
		this.f_updateBreakpoint();

		///h : burger
		jQuery('.content_menu_burger').bind('click', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_showMenu();});	
		
		///h hilite
		this.f_hilite();
		
		///h :
	//	this.f_initPadding();
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__menu.prototype.f_initPadding = function()
		{
		///h : properties
		var $s_color = null;		///h :

		///h :
		if(this.__o_data['s_layout_type'] !== 'menu1')
			{
			///h :
			return;
			}

		///h : add menu background color so animation does not reveal white background
		$jqo_segments = jQuery(om3__frontend.__o_data['jqo_segments'][0]);
		$s_color = $jqo_segments.css('backgroundColor');
		if($s_color !== undefined)
			{
			///h : add element to page
			$jqo_segment = om3__frontend.__o_data['jqo_page'].find('> .neos-contentcollection:first-child');
			$jqo_segment.prepend('<div class=\'om3__menubackground\'></div>');

			///h : colorize element
			jQuery('.om3__menubackground').css({'background-color':$s_color});
			}
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__menu.prototype.f_hilite = function()
		{
		///h :
		var $jqo_element = null;		///h :
		var $i_loop = null;			///h :
		var $o_uri = null;			///h :
		
		///h :
		if(dc7__request.__o_data['o_uri']['s_path'] === null)
			{
			///h :
			for($i_loop = 0; $i_loop < this.__o_data['i_link']; $i_loop ++)
				{
				///h :
				$jqo_element = jQuery(this.__o_data['jqo_link'][$i_loop]);

				///h :
				$o_uri = (dc7__request.f_parseURI($jqo_element.attr('href')));

				///h :
				if($o_uri['s_path'] === null)
					{
					///h :
					if($jqo_element.text().toLowerCase() === 'start')
						{
						///h :
						$s_uri = ($o_uri['s_uri']);
						if($o_uri['s_port'] != '80')
							{
							///h :
							$s_uri += (':' + $s_uri);
							}
						
						///h :
						$jqo_element.parent().addClass('current');

						///h :
						$jqo_element.attr('href', $s_uri);
						}
					}
				}
			}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__menu.prototype.f_initData = function($o_config)
		{
		///h : data 
		this.__o_data = {};
		this.__o_data['o_config'] = $o_config;
		this.__o_data['jqo_element'] = $o_config['jqo_element'];
		this.__o_data['b_menu_visible'] = true;
		this.__o_data['s_layout_type'] = (($o_config['s_layout_type'] === undefined) ? 'menu1' : $o_config['s_layout_type']);
		this.__o_data['jqo_logo_1_img'] = this.__o_data['jqo_element'].find('.content_menu_logo_img');
		this.__o_data['jqo_logo_2_img'] = this.__o_data['jqo_element'].find('.content_menu_logo2_img');
		this.__o_data['jqo_link'] = jQuery('.nav-pills').find('a');
		this.__o_data['i_link'] = this.__o_data['jqo_link'].length;
		}

///*    init class ///////////////////////////////////////////////////////////////////////////////////////////
jQuery(document).bind('ready', function(){om3__behavior__menu.f_initSystem();});

/*
--------------------------------------------------------------------------------------------------------------
description:
--------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------
*/