//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__modal(){this.f_construct();}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__modal.__o_data = null;				///h : stores class data
	dc7__modal.__o_config = null;				///h : stores class config


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__modal.f_initSystem = function()
		{
		dc7__modal.f_init();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init
	/**
	 * init
	 * init class calling all default functions like initenvironment, initdata
	 * initcontent.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_init = function()
		{
		///: init config
		dc7__modal.f_initData();

		///: init config
		dc7__modal.f_initContent();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init data
	 * used to init all data later on used in the class context like
	 * configuration arrays etc.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_initConfig = function($o_config)
		{
		///h :
		$o_config = (($o_config === undefined) ? {} : $o_config);
		dc7__modal.__o_data['i_content_width_max'] = (($o_config['i_content_width_max'] === undefined) ? -1 : $o_config['i_content_width_max']);
		dc7__modal.__o_data['i_content_height_max'] = (($o_config['i_content_height_max'] === undefined) ? -1 : $o_config['i_content_height_max']);
		dc7__modal.__o_data['b_content_width_resize'] = (($o_config['b_content_width_resize'] === undefined) ? true : $o_config['b_content_width_resize']);
		dc7__modal.__o_data['b_content_height_resize'] = (($o_config['b_content_height_resize'] === undefined) ? true : $o_config['b_content_height_resize']);

		///h : resize if rendered
		if(dc7__modal.__o_data['b_modal_rendered'] === true)
			{
			dc7__modal.f_resize();
			}

		///h :
		jQuery(document).on('mousemove', {}, function($event_data){dc7__modal.f_triggerEvents($event_data);});
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init data
	 * used to init all data later on used in the class context like
	 * configuration arrays etc.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_triggerEvents = function($event_data)
		{
		///h :
		if(window.self !== window.top)
			{
			///h :
			top.f_updateMousePos($event_data.clientX, $event_data.clientY);
			}
		}  


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init data
	 * used to init all data later on used in the class context like
	 * configuration arrays etc.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_initData = function()
		{
		dc7__modal.__o_data = {};
		dc7__modal.__o_data['o_content_data'] = {};
		dc7__modal.__o_data['b_open'] = false;
		dc7__modal.__o_data['o_config'] = null;
		dc7__modal.__o_data['a_page_data'] = null;
		dc7__modal.__o_data['i_page_index'] = -1;
		dc7__modal.__o_data['b_loader_active'] = false;
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_initContent = function()
		{	
		///h : init config
		dc7__modal.f_initConfig();

		///h : add modal
		dc7__modal.f_renderModal();

		///h : init resize
		dc7__modal.f_initResize();
 
		///h :
		jQuery(window).on('modal||close', {}, function(){jQuery('.dc7__modal_close').trigger('click');});
		
		//console.log('***resizetrigger');
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_initResize = function()
		{
		dc7.__o_data['dom__jqo_window'].bind('dc7__behavior__resize__resize', {}, function(){dc7__modal.f_resize();});
		dc7__modal.f_resize();
		}
	
	
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_resize = function()
		{
		///h : properties
		var $i_width = -1;		///h :
		var $i_height = -1;		///h :
		var $i_x = -1;			///h :
		var $i_y = -1;			///h :

		///h : data
		$i_width = dc7.__o_data['window__i_width'];
		$i_height = dc7.__o_data['window__i_height'];

		///h : max values
		if(dc7__modal.__o_data['i_content_width_max'] !== -1)
			{
			$i_width = (($i_width > dc7__modal.__o_data['i_content_width_max']) ? dc7__modal.__o_data['i_content_width_max'] : $i_width);
			}
		if(dc7__modal.__o_data['i_content_height_max'] !== -1)
			{
			$i_height = (($i_height > dc7__modal.__o_data['i_content_height_max']) ? dc7__modal.__o_data['i_content_height_max'] : $i_height);
			}

		///h : size
		dc7__modal.__o_data['jqo_modal_content'].css({'width':$i_width, 'height':$i_height});

		///h : position
		$i_x = Math.round((dc7.__o_data['window__i_width'] * 0.5) - (dc7__modal.__o_data['jqo_modal_content'].width() * 0.5));
		$i_y = Math.round((dc7.__o_data['window__i_height'] * 0.5) - (dc7__modal.__o_data['jqo_modal_content'].height() * 0.5));
		dc7__modal.__o_data['jqo_modal_content'].css({'left':$i_x, 'top':$i_y});
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_keyHandler = function($o_event)
		{
		/// : escape
		if($o_event.keyCode === 27)
			{
			dc7__modal.f_close();
			return;
			}
		}
	
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_renderModal = function()
		{
		///h : properties
		var $s_html = null;		///h : 

		///h : modal 
		$s_html = '';
		$s_html += ('<div class=\'dc7__modal\'>');
		
		///h : modal - loader
		$s_html += ('<div class=\'dc7__modal_loader dc7__modal__icon\'>');
		$s_html += ('	<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="3" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>');
		$s_html += ('</div>'); 

		///h : modal - content
		$s_html += ('<div class=\'dc7__modal_close dc7__modal__icon __layout__icon\'>');
	//	$s_html += ('	<i class=\'fa fa-close fa-1x\' aria-hidden=\'true\'></i>'); 
		$s_html += ('	<img src=\'/_Resources/Static/Packages/permanent.belsana/BMP/icons/close_white.png\'/>');
		$s_html += ('</div>');

		///h : modal - content
		$s_html += ('<div class=\'dc7__modal_content\'>');
		$s_html += ('</div>');

		///h : modal - background
		$s_html += ('<div class=\'dc7__modal_background\'>');
		$s_html += ('</div>');

		$s_html += ('</div>');

		///h : 
		dc7.__o_data['dom__jqo_body'].prepend($s_html);

		///h : cached jquery handler
		dc7__modal.__o_data['jqo_modal'] = jQuery('.dc7__modal');
		dc7__modal.__o_data['jqo_modal_content'] = jQuery('.dc7__modal_content');
		dc7__modal.__o_data['jqo_modal_close'] = jQuery('.dc7__modal_close');
		dc7__modal.__o_data['jqo_modal_loader'] = jQuery('.dc7__modal_loader');
		dc7__modal.__o_data['jqo_modal_background'] = jQuery('.dc7__modal_background');
		dc7__modal.__o_data['b_modal_rendered'] = true;

		///h :
		dc7.__o_data['dom__jqo_document'].bind('keydown', {}, function($o_event){dc7__modal.f_keyHandler($o_event);});

		///h : 
		dc7__modal.__o_data['jqo_modal_background'].bind('click', {}, function(){dc7__modal.f_close();});
		 
		///h : 
		dc7__modal.__o_data['jqo_modal_close'].bind('click', {}, function(){dc7__modal.f_close();});

		///h : 
	//	TweenMax.set(dc7__modal.__o_data['jqo_modal'], {'autoAlpha':0, 'scale':0.95, 'display':'block', 'ease':Expo.easeOut, 'overwrite':'auto', 'delay':0});
		TweenMax.set(dc7__modal.__o_data['jqo_modal'], {'autoAlpha':0, 'scale':1.00, 'display':'block', 'ease':Expo.easeOut, 'overwrite':'auto', 'delay':0});
		TweenMax.set(dc7__modal.__o_data['jqo_modal_loader'], {'autoAlpha':0, 'display':'block', 'ease':Expo.easeOut, 'overwrite':'auto', 'delay':0});
		}
	
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_getContentPagesArray = function($s_content_id)
		{
		///h : 
		dc7__modal.__o_data['o_content_data'][$s_content_id] = ((dc7__modal.__o_data['o_content_data'][$s_content_id] === undefined) ? [] : dc7__modal.__o_data['o_content_data'][$o_config['s_content_id']]);

		///h : return
		return(dc7__modal.__o_data['o_content_data'][$s_content_id]);
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_addPage = function($o_config)
		{	
		///h : properties
		var $a_content_pages = null;

		///h : config
		$o_config = (($o_config === undefined) ? {} : $o_config);
		$o_config['s_content_id'] = (($o_config['s_content_id'] === undefined) ? 'default' : $o_config['s_content_id']);
		$o_config['i_page_data_index'] = (($o_config['i_page_data_index'] === undefined) ? -1 : $o_config['i_page_data_index']);
		$o_config['s_content_dom_selector'] = (($o_config['s_content_dom_selector'] === undefined) ? null : $o_config['s_content_dom_selector']);

		///h : get content pages array
		$a_content_pages = dc7__modal.f_getContentPagesArray($o_config['s_content_id']);

		///h : get index
		$o_config['i_page_data_index'] = (($o_config['i_page_data_index'] === -1) ? $a_content_pages.length : $o_config['i_page_data_index']);

		///h : add page
		$a_content_pages[$o_config['i_page_data_index']] = $o_config;
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_renderContent = function()
		{
		
		}
		

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_initPagesData = function($o_config)
		{
		///h : properties
		var $storage_page = null;	///h : 

		///h : reset page data
		dc7__modal.__o_data['a_page_data'] = [];
		dc7__modal.__o_data['i_page_data_index_start'] = -1;

		///h : add page by function
		if($o_config['jqo_element'] === undefined)
			{
			dc7__modal.__o_data['a_page_data'].push($o_config);
			dc7__modal.__o_data['i_page_data_index_start'] = 0;
			}
		///h : add pages by parsing dom object
		else
			{
			$jqo_elements = jQuery('.dc7__modal__call');
			$i_elements = $jqo_elements.length;
			$b_dom_call = false;
			$i_page_data_index = 0;
			for($i_loop = 0; $i_loop < $i_elements; $i_loop ++)
				{
				///h : get element
				$jqo_element = jQuery($jqo_elements[$i_loop]);
				
				///h : get element data
				$s_data = $jqo_element.data('dc7__modal');
				$o_data = dc7__string.f_getDataObject($s_data);
				
				///h : check
				if(($o_data['s_content_type'] === undefined) && ($o_data['s_content_uri'] !== undefined))
					{
					$o_data['s_content_extension'] = $o_data['s_content_uri'].toLowerCase().split('.').pop();
		
					///h : check media type
					if(['jpg', 'bmp', 'png'].indexOf($o_data['s_content_extension']) > -1)
						{
						$o_data['s_content_type'] = 'image';
						}
					}
				$o_data['s_content_type'] = (($o_data['s_content_type'] === undefined) ? 'iframe' : $o_data['s_content_type']);

				///h : check content id
				if((($o_data['s_content_id'] === $o_config['s_content_id']) && ($o_config['s_content_uri'] !== undefined) && ($o_config['s_content_id'] !== undefined)) || ($jqo_element[0] === $o_config['jqo_element'][0]))
					{
					dc7__modal.__o_data['a_page_data'].push($o_data);
					dc7__modal.__o_data['i_page_data_index_start'] = (($jqo_element === $o_config['jqo_element']) ? $i_page_data_index : dc7__modal.__o_data['i_page_data_index_start']);
					}
				}
			}
		dc7__modal.__o_data['i_page_data'] = dc7__modal.__o_data['a_page_data'].length;
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_close = function($o_config)
		{
		///h : data
		$o_config = (($o_config === undefined) ? {} : $o_config);
		$o_config['b_open'] = false;

		///h : open
		dc7__modal.f_open($o_config);
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_initPages = function()
		{
		///h : properties
		var $s_html = null;		///h : 
		var $storage_page = null;	///h : 
		var $i_loop = -1;		///h : 

		///h : check transmitted config
		$s_html = '';
		for($i_loop = 0; $i_loop < dc7__modal.__o_data['i_page_data']; $i_loop ++)
			{
			$storage_page = dc7__modal.__o_data['a_page_data'][$i_loop];
			
			///h : styles
			$s_style = '';
			$s_style += ($storage_page['s_max_width'] !== undefined ? ('max-width:' + $storage_page['s_max_width'] + ';') : '');
	
			///h : wrapper
			$s_html += ('<div class=\'dc7__modal_content_page dc7__modal_content_page__' + $storage_page['s_content_type'] + '\' width:\'100%\' height:\'100%\' style=\'' + $s_style + '\'>');
			$s_html += ('</div>');

			///h : get relevant page
			$storage_page['b_render_complete'] = false;
			$storage_page['b_load_complete'] = false;
			}

		///h : 
		dc7__modal.__o_data['jqo_modal_content'].append($s_html);

		///h :
		for($i_loop = 0; $i_loop < dc7__modal.__o_data['i_page_data']; $i_loop ++)
			{
			$storage_page = dc7__modal.__o_data['a_page_data'][$i_loop];
			$storage_page['jqo_page'] = jQuery(jQuery('.dc7__modal_content_page')[$i_loop]);
			$storage_page['i_page_index'] = $i_loop;
			}
			
		///h : hide all pages initially
		dc7__modal.__o_data['jqo_pages'] = jQuery('.dc7__modal_content_page');
		TweenMax.set(dc7__modal.__o_data['jqo_pages'], {'autoAlpha':0, 'display':'inline-block', 'ease':Expo.easeOut, 'overwrite':'auto', 'delay':0});
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_open_complete = function($o_config)
		{
		if(dc7__modal.__o_data['b_open'] === false)
			{
			///h : remove all content
			dc7__modal.__o_data['jqo_modal_content'].empty();
			}
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_showLoader = function($b_loader_active, $b_animation)
		{
		///h : check
		if($b_loader_active === dc7__modal.__o_data['b_loader_active'])
			{
			return;
			}
		dc7__modal.__o_data['b_loader_active'] = $b_loader_active;
		
		///h : trigger
		console.log('***resizetrigger');
		
		///h : tween
		$num_delay = (($b_loader_active === false) ? 0.2 : 0);
		$num_duration = (($b_animation !== false) ? 0.8 : 0);
		$num_opacity = ((dc7__modal.__o_data['b_loader_active'] === true) ? 1 : 0);

		///h :
		TweenMax.to(dc7__modal.__o_data['jqo_modal_loader'], $num_duration, {'autoAlpha':$num_opacity, 'ease':Expo.easeOut, 'overwrite':'auto', 'delay':$num_delay});
	
	}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_renderPage = function()
		{
		///h : properties
		var $s_html = null;		///h :

		///h : 
		$s_html = ('');
		
		///h : iframe
		if(dc7__modal.__o_data['storage_page']['s_content_type'] === 'iframe')
			{
			///h : iframe 
			$s_html += ('<iframe class=\'test dc7__modal_content_page_content\' src=\'' + dc7__modal.__o_data['storage_page']['s_content_uri'] + '\' allowfullscreen frameborder=\'0\'>');
			$s_html += ('</iframe>');
			}
		
		///h : image
		else if(dc7__modal.__o_data['storage_page']['s_content_type'] === 'image')
			{
			///h : image
			$s_html += ('<img class=\'dc7__modal_content_page_content\' src=\'' + dc7__modal.__o_data['storage_page']['s_content_uri'] + '\' />');
			}

		///h : append
		dc7__modal.__o_data['storage_page']['jqo_page'].html($s_html);
		dc7__modal.__o_data['storage_page']['b_render_complete'] = true;
		dc7__modal.__o_data['storage_page']['jqo_page_content'] = dc7__modal.__o_data['storage_page']['jqo_page'].find('.dc7__modal_content_page_content').first();

		///h : load
		dc7__modal.__o_data['storage_page']['jqo_page_content'].bind('load', {'i_page_index':dc7__modal.__o_data['storage_page']['i_page_index']}, function($o_event){dc7__modal.f_activatePage($o_event.data['i_page_index'], true, false);});
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_initPageContent = function()
		{
		///h : iframe
		if(dc7__modal.__o_data['storage_page']['s_content_type'] === 'image')
			{
			dc7__modal.__o_data['storage_page']['jqo_page'].zoom({'url':dc7__modal.__o_data['storage_page']['s_content_uri'], 'on':'grab'});
			}
		}

		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_activatePage = function($i_page_index, $b_intern_call, $b_init)
		{
		///h :
		var $num_duration = null;	///h :
			
		///h : properties
		if(($i_page_index === dc7__modal.__o_data['i_page_index']) && ($b_intern_call !== true))
			{
			return;
			}
		dc7__modal.__o_data['i_page_index'] = $i_page_index;

		
		///h : hide all pages
		if($b_intern_call === false)
			{
			///h : hide all pages
			TweenMax.set(dc7__modal.__o_data['jqo_pages'], {'autoAlpha':0, 'display':'inline-block', 'ease':Expo.easeOut, 'overwrite':'auto', 'delay':0});
			}

		///h : get current page data
		dc7__modal.__o_data['storage_page'] = dc7__modal.__o_data['a_page_data'][$i_page_index];
		if(dc7__modal.__o_data['storage_page'] === undefined)
			{
			return;
			}
		
		///h : check rendering
		if(dc7__modal.__o_data['storage_page']['b_render_complete'] === false)
			{
			///h : show loader
			dc7__modal.f_showLoader(true);
	
			///h : render page
			dc7__modal.f_renderPage();
			
			///h : init page content
			dc7__modal.f_initPageContent();

			///h : return
			return;
			}
			
		///h : add content class if iframe
		if(dc7__modal.__o_data['storage_page']['s_content_type'] === 'iframe')
			{	
			///h : check link is external	
			if(dc7__request.f_isExternal(dc7__modal.__o_data['storage_page']['s_content_uri']) === false)
				{
				///h :
				dc7__modal.__o_data['jqo_modal'].find('iframe').contents().find('html').first().addClass('dc7__modal__bs_in_modal__true');
				}
			}

		///h : show
		dc7__modal.f_showLoader(false);
		
		///h : trigger
//		dc7.__o_data['dom__jqo_window'].trigger('dc7__modal__activatepage');
//		jQuery(window).trigger('resize');

		console.log('***resizetrigger');
		

		///h : show page
		
		$num_duration = 0.80;
		
	//	TweenMax.to(dc7__modal.__o_data['storage_page']['jqo_page'], $num_duration, {'force3D':true, 'autoAlpha':1, 'ease':Expo.easeOut, 'overwrite':'auto', 'delay':0});
		TweenMax.to(dc7__modal.__o_data['storage_page']['jqo_page'], $num_duration, {'autoAlpha':1, 'ease':Expo.easeOut, 'overwrite':'auto', 'delay':0});
	
	}
	
	
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_open__resize = function()
		{	
		///h : 
		console.log('***resizetrigger');
		dc7__modal.f_resize();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__modal.f_open = function($o_config)
		{
		///h : properties
		var $i_elements = -1;			///h : 
		var $i_loop = -1;			///h : 
		var $bs_active = null;			///h :

		///h : data
		$o_config = (($o_config === undefined) ? {} : $o_config);
		$o_config['b_open'] = (($o_config['b_open'] === undefined) ? true : $o_config['b_open']);
		$o_config['b_animation'] = (($o_config['b_animation'] === undefined) ? true : $o_config['b_animation']);
		
		///h : 
		if(window.self !== window.top)
			{
			///h :
			location.href = $o_config['s_content_uri'];
			return;
			}
		
		///h : return
		if(dc7__modal.__o_data['b_open'] === $o_config['b_open'])
			{
			return;
			}
		dc7__modal.__o_data['b_open'] = $o_config['b_open'];

		///h : 
		$bs_active = (($o_config['b_open'] === true) ? 'true' : 'false');
		dc7__dom.f_setClassVar(dc7__modal.__o_data['jqo_modal'], '__bs_open', $bs_active);
		dc7__dom.f_setClassVar(dc7.__o_data['dom__jqo_html'], '__bs_modal', $bs_active);

		///h : get pages data
		if(dc7__modal.__o_data['b_open'] === true)
			{
			///h : init pages data
			dc7__modal.f_initPagesData($o_config);

			///h : render pages
			dc7__modal.f_initPages();

			///h : show initial page
			dc7__modal.f_activatePage(0, false, true);
			}
		else
			{
			///h : reset page index
			dc7__modal.__o_data['i_page_index'] = -1;
			}

		///h : tween
		$num_duration = (($o_config['b_animation'] !== false) ? 0.80 : 0.00);
		$num_opacity = ((dc7__modal.__o_data['b_open'] === true) ? 1.00 : 0.00);
		$num_scale = ((dc7__modal.__o_data['b_open'] === true) ? 1 : 0.95);

		///h :
		TweenMax.to(dc7__modal.__o_data['jqo_modal'], $num_duration, {'autoAlpha':$num_opacity, 'ease':Expo.easeOut, 'overwrite':'auto', 'delay':0, 'onComplete':function(){dc7__modal.f_open_complete();}});

		///h :
		TweenMax.delayedCall(0.20, function(){dc7__modal.f_open__resize();})
		} 
		

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__modal.f_initSystem();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
