//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

   
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__grid(){this.f_construct();}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__grid.__o_data = null;				///h : stores class data
	dc7__grid.__o_config = null;				///h : stores class config


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__grid.f_initSystem = function()
		{
		dc7__grid.f_init();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init
	/**
	 * init
	 * init class calling all default functions like initenvironment, initdata
	 * initcontent.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__grid.f_init = function()
		{
		///: init config
		dc7__grid.f_initData();

		///: init config
		dc7__grid.f_initContent();
		}
	
//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init data
	 * used to init all data later on used in the class context like
	 * configuration arrays etc.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__grid.f_isTouchscreen = function()
		{
		return('ontouchstart' in window || 'onmsgesturechange' in window);
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init data
	 * used to init all data later on used in the class context like
	 * configuration arrays etc.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__grid.f_initData = function()
		{
		dc7__grid.__o_data = {};
		dc7__grid.__o_data['a_breakpoints'] = [];
		dc7__grid.__o_data['i_breakpoints'] = 0;
		dc7__grid.__o_data['s_breakpoint_id'] = null;
		dc7__grid.__o_data['storage_breakpoint'] = null;
		dc7__grid.__o_data['o_device'] = new MobileDetect(window.navigator.userAgent);
		dc7__grid.__o_data['s_device_phone'] = dc7__grid.__o_data['o_device'].phone();
		dc7__grid.__o_data['s_device_tablet'] = dc7__grid.__o_data['o_device'].tablet();
		dc7__grid.__o_data['s_device_mobile'] = dc7__grid.__o_data['o_device'].mobile();
		dc7__grid.__o_data['s_device'] = null;
		dc7__grid.__o_data['s_device_orientation'] = null;
		dc7__grid.__o_data['s_device_type'] = 'desktop';
		if(dc7__grid.__o_data['s_device_phone'] !== null)
			{
			dc7__grid.__o_data['s_device_type'] = 'smartphone';
			}
		else if(dc7__grid.__o_data['s_device_tablet'] !== null)
			{
			dc7__grid.__o_data['s_device_type'] = 'tablet';
			}
		dc7__grid.__o_data['b_touchscreen'] = dc7__grid.f_isTouchscreen();
		
		///h : 
		dc7__grid.__o_data['animation__b_transition'] = true;
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__grid.f_initConfig = function($o_config)
		{
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__grid.f_initContent = function()
		{
		///h : default config
		dc7__grid.f_resetBreakpoints();
		dc7__grid.f_addBreakpoint({'s_breakpoint_id':'sma', 'i_window_width_max':554, 'a_device_types':['smartphone_por', 'smartphone_lan', 'tablet_por']});
		dc7__grid.f_addBreakpoint({'s_breakpoint_id':'tab', 'i_window_width_max':992, 'b_tablet':true, 'a_device_types':['tablet_lan']});
		dc7__grid.f_addBreakpoint({'s_breakpoint_id':'des', 'i_window_width_min':993, 'b_desktop':true});

		///h : 
		dc7__dom.f_setClassVar(dc7.__o_data['dom__jqo_html'], 'dc7__grid__bs_touchscreen', dc7__grid.__o_data['b_touchscreen']);	

		///h : update
		dc7.__o_data['dom__jqo_window'].bind('resize orientationchange load', {}, function($o_event){dc7__grid.f_updateGrid($o_event.type);});
		dc7__grid.f_updateGrid('init');
 
		///h : no css transition on resize
		dc7.__o_data['dom__jqo_window'].bind('resize', {}, function(){dc7__grid.animation__f_transition(false);});
		dc7.__o_data['dom__jqo_window'].bind('load dc7__behavior__load__ready__delayed0.1s dc7__behavior__resize__resize__delayed0.8s', {}, function($event_data){dc7__grid.animation__f_transition(true);});

		///h :
		dc7__grid.animation__f_transition(false);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__grid.animation__f_transition = function($b_transition)
		{
		///h :
		if(dc7__grid.__o_data['animation__b_transition'] === $b_transition)
			{
			return;
			}
		dc7__grid.__o_data['animation__b_transition'] = $b_transition;
		
		
		///h :
		dc7__dom.f_setClass(dc7.__o_data['dom__jqo_html'], 'dc7__animation__notransition', !$b_transition);
		}
	

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : debug
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__grid.debug__f_renderGuides = function()
		{
		///h : container
		$jqo_elements = jQuery('.dc7__grid__container');
		$i_elements = $jqo_elements.length;
		for($i_loop = 0; $i_loop < $i_elements; $i_loop++)
			{
			$jqo_element = jQuery($jqo_elements[$i_loop]);
			$jqo_element.append('<div class=\'dc7__grid__container__guides__outer\' style=\'pointer-events: none; cursor: default; top:0px; left:0px; width:100%; height:100%; position:absolute; border:1px dashed lightgreen;\'></div>');
			$jqo_element.append('<div class=\'dc7__grid__container__guides__inner\' style=\'pointer-events: none; cursor: default; top:0px; left:0px; width:100%; height:100%; position:absolute; border:1px dashed green;\'></div>');
			}

		///h : container
		$jqo_elements = jQuery('.dc7__grid__item');
		$i_elements = $jqo_elements.length;
		for($i_loop = 0; $i_loop < $i_elements; $i_loop++)
			{
			$jqo_element = jQuery($jqo_elements[$i_loop]);
			$jqo_element.append('<div class=\'dc7__grid__item__guides__outer\' style=\'pointer-events: none; cursor: default; top:0px; left:0px; width:100%; height:100%; position:absolute; border:1px dashed lightcoral;\'></div>');
			$jqo_element.append('<div class=\'dc7__grid__item__guides__inner\' style=\'pointer-events: none; cursor: default;top:0px; left:0px; width:100%; height:100%; position:absolute; border:1px dashed red;\'></div>');
			}

		///h : redize
		dc7.__o_data['dom__jqo_window'].bind('resize orientationchange load', {}, function($o_event){dc7__grid.debug__f_resizeGuides();});
		dc7__grid.debug__f_resizeGuides();
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__grid.debug__f_resizeGuides = function()
		{
		///h : loop
		$jqo_elements = jQuery('.dc7__grid__container__guides__inner, .dc7__grid__item__guides__inner');
		$i_elements = $jqo_elements.length;
		for($i_loop = 0; $i_loop < $i_elements; $i_loop++)
			{
			$jqo_element = jQuery($jqo_elements[$i_loop]);
			$i_width = Math.round($jqo_element.parent().width());
			$i_height = Math.round($jqo_element.parent().height());
			$i_x = $jqo_element.parent().css('padding-left');
			$i_y = $jqo_element.parent().css('padding-top');

			///h :
			$jqo_element.css({'width':$i_width, 'height':$i_height, 'left':$i_x, 'top':$i_y});
			}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : grid
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__grid.f_updateGrid = function($s_event_id)
		{
		///h : properties
		var $storage_breakpoint = null;		///h : 
		var $b_breakpoint = false;		///h : 
		var $i_loop = -1;			///h : 
		var $i_elements = -1;			///h : 

		///h : 
		$s_event_id = (($s_event_id === undefined) ? 'reset' : $s_event_id);
		dc7__grid.__o_data['s_device_orientation'] = ((window.innerHeight < window.innerWidth) ? 'lan' : 'por');
		$s_device = (dc7__grid.__o_data['s_device_type'] + '_' + dc7__grid.__o_data['s_device_orientation']);

		///h : parse breakpoints - devicetypes
		for($i_loop = 0; $i_loop < dc7__grid.__o_data['i_breakpoints']; $i_loop++)
			{
			$storage_data = dc7__grid.__o_data['a_breakpoints'][$i_loop];

			///h : check : device type with mobile detect if directly transmitted
			if($storage_data['i_device_types'] > 0)
				{
				///h : loop
				for($i_loop_child = 0; $i_loop_child < $storage_data['i_device_types']; $i_loop_child++)
					{
					$s_device_type = $storage_data['a_device_types'][$i_loop_child];
					if(($s_device_type === $s_device) || ($s_device_type === dc7__grid.__o_data['s_device_type']))
						{
						$storage_breakpoint = $storage_data;
						break;
						}
					}
				}
			}
 
		///h : parse breakpoints
		if($storage_breakpoint === null)
			{
			$jqo_content = jQuery('#content');
			$i_width = (($jqo_content.length === 0) ? dc7.__o_data['window__i_width'] : $jqo_content.width());
			
			for($i_loop = 0; $i_loop < dc7__grid.__o_data['i_breakpoints']; $i_loop++)
				{
				$storage_data = dc7__grid.__o_data['a_breakpoints'][$i_loop];
	
				///h : 
				$b_breakpoint = true;

				///h : check : max width
				if($storage_data['i_window_width_max'] !== undefined)
					{
					///h : max width
					if($i_width > $storage_data['i_window_width_max'])
						{
						$b_breakpoint = false;
						}
					}

				///h : check : min width
				if($storage_data['i_window_width_min'] !== undefined)
					{
					///h : max width
					if($i_width < $storage_data['i_window_width_min'])
						{
						$b_breakpoint = false;
						}
					}

				if($b_breakpoint === true)
					{
					$storage_breakpoint = $storage_data;
					break;
					}
				}
			}

		///h : check
		if($storage_breakpoint !== null)
			{
			if(($storage_breakpoint['s_breakpoint_id'] !== dc7__grid.__o_data['s_breakpoint_id']) || (dc7__grid.__o_data['s_device'] !== $s_device) || (dc7__grid.__o_data['b_reset'] === true))
				{					
				///h :
				dc7__grid.__o_data['s_device'] = $s_device;

				///h : new breakpoint
				dc7__grid.__o_data['s_breakpoint_id'] = $storage_breakpoint['s_breakpoint_id'];
				dc7__grid.__o_data['storage_breakpoint'] = $storage_breakpoint;

				///h : add to html classes - breakpoint id
				dc7__dom.f_setClassVar(dc7.__o_data['dom__jqo_html'], 'dc7__grid__s_breakpoint_id', dc7__grid.__o_data['s_breakpoint_id']);

				///h : add to html classes - device and device type
				dc7__dom.f_setClassVar(dc7.__o_data['dom__jqo_html'], 'dc7__grid__s_type', dc7__grid.__o_data['s_device_type']);
				dc7__dom.f_setClassVar(dc7.__o_data['dom__jqo_html'], 'dc7__grid__s_device', dc7__grid.__o_data['s_device']);

				///h : reset html root fontsize
				$i_fontsize = ((dc7__grid.__o_data['storage_breakpoint']['i_fontsize'] === undefined) ? 16 : dc7__grid.__o_data['storage_breakpoint']['i_fontsize']);
				dc7.__o_data['dom__jqo_html'].css({'fontSize':$i_fontsize});

				///h : trigger
				dc7.__o_data['dom__jqo_window'].trigger('dc7__grid__breakpointchange');

				///h : 
				dc7__grid.__o_data['b_reset'] = false;
				}
			}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////	
	///h : init content
	/**
	 * init content 
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param 
	 * @return vault
	 *
	 */
	dc7__grid.f_addBreakpoint = function($o_config)
		{
		///h : 
		$o_config['s_breakpoint_id'] = (($o_config['s_breakpoint_id'] === undefined) ? 'def' : $o_config['s_breakpoint_id']);
		$o_config['i_device_types'] = (($o_config['a_device_types'] !== undefined) ? $o_config['a_device_types'].length : 0);

		///h :
		dc7__grid.__o_data['a_breakpoints'].push($o_config);
		dc7__grid.__o_data['i_breakpoints'] = dc7__grid.__o_data['a_breakpoints'].length;
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////	
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 * 
	 */
	dc7__grid.f_resetBreakpoints = function($o_config)
		{
		dc7__grid.__o_data['a_breakpoints'] = [];
		dc7__grid.__o_data['i_breakpoints'] = 0;
		dc7__grid.__o_data['b_reset'] = true;
		}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__grid.f_initSystem();});
 

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
