/*
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
code:		plustic â€” massimo cardascia â€” www.plustic.de
code language:	js 5
code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
*/

///*	class definition /////////////////////////////////////////////////////////////////////////////////////
function project__behavior__sameheight($o_config){this.f_construct($o_config);}
   
///*    class properties /////////////////////////////////////////////////////////////////////////////////////

///*    class system init ////////////////////////////////////////////////////////////////////////////////////
	project__behavior__sameheight.f_initSystem = function()
		{
		project__behavior__sameheight.f_init();
		}

///*    class constructor ////////////////////////////////////////////////////////////////////////////////////
	project__behavior__sameheight.prototype.f_construct = function($o_config)
		{
		///h : init
		this.f_init($o_config);
		}

///*    public static functions //////////////////////////////////////////////////////////////////////////////

///*    public functions /////////////////////////////////////////////////////////////////////////////////////

///*    pivate static functions //////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class static methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__sameheight.f_init = function()
		{
		///h :
		project__behavior__sameheight.f_initData();
		
		///h :
		project__behavior__sameheight.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__sameheight.f_initData = function()
		{
		///h :
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__sameheight.f_initContent = function()
		{
		///h : init behavior
		dc7__behavior.f_initBehaviorClass({'s_behavior_id':'project__behavior__sameheight', 'class_behavior':project__behavior__sameheight, 'selector_behavior':'.__b_childssameheight__true'});
		dc7__behavior.f_updateElements();
		}

 
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__sameheight.prototype.f_init = function($o_config)
		{
		///h : update important values
		this.f_initData($o_config);

		///h : init engine
		this.f_initContent();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__sameheight.prototype.f_initData = function($o_config)
		{
		///h : data 
		this.__o_data = {};
		this.__o_data['o_config'] = $o_config; 
		this.__o_data['jqo_element'] = $o_config['jqo_element'];
		this.__o_data['jqo_child'] = $o_config['jqo_element'].find('> .neos-contentcollection > .om3__segment, > .neos-contentcollection > a > .om3__segment, .om3__segment__storefinder__results__content__element');
		this.__o_data['i_child'] = this.__o_data['jqo_child'].length;
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__sameheight.prototype.f_initContent = function()
		{
		dc7.__o_data['dom__jqo_window'].on('resize load', {'scope_data':this}, function($o_event){$o_event.data['scope_data'].f_updateHeight();});
		dc7.__o_data['dom__jqo_document'].on('click', {'scope_data':this}, function($o_event){$o_event.data['scope_data'].f_updateHeight(true);});
		this.f_updateHeight();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */ 
	project__behavior__sameheight.prototype.f_updateHeight = function($b_update)
		{
		///h :
		var $i_loop = null;			///h :
		var $jqo_dom = null;			///h :
		var $i_data = null;			///h :
		var $i_height = null;			///h :
		var $i_height__current = null;		///h :

		///h :
		this.__o_data['jqo_child'].css({'height':'auto'});
		this.__o_data['jqo_child'].addClass('_same_height__true');
		
		///h :
		if(dc7__grid.__o_data['s_breakpoint_id'] !== 'sma')
			{
			///h : 
			$i_height = 0;
			for($i_loop = 0; $i_loop < this.__o_data['i_child']; $i_loop ++)
				{ 
				///h :
				$jqo_dom = jQuery(this.__o_data['jqo_child'][$i_loop]);
				$jqo_dom.children('.neos-contentcollection').css({'height':'auto'}); 
				$i_height__current = $jqo_dom.outerHeight();
				$i_height = (($i_height__current > $i_height) ? $i_height__current : $i_height);
				}

			///h :
			for($i_loop = 0; $i_loop < this.__o_data['i_child']; $i_loop ++)
				{
				///h :
				$jqo_dom = jQuery(this.__o_data['jqo_child'][$i_loop]); 
				$jqo_dom.children('.neos-contentcollection').css({'height':'100%'}); 
				$jqo_dom.css({'height':$i_height});
				}
			}
		}


///*    init class ///////////////////////////////////////////////////////////////////////////////////////////
jQuery(document).bind('ready', function(){project__behavior__sameheight.f_initSystem();});

/*
--------------------------------------------------------------------------------------------------------------
description:
--------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------
*/