//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__behavior__resize($o_config){this.f_construct($o_config);}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__behavior__resize.__o_data = null;				///h : stores class data
	dc7__behavior__resize.__o_config = null;				///h : stores class config

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__behavior__resize.f_initSystem = function()
		{
		dc7__behavior__resize.f_init();
		}
	

	/////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class construct
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__behavior__resize.prototype.f_construct = function($o_config)
		{
		this.f_init($o_config);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class static methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__resize.f_init = function()
		{
		dc7__behavior__resize.f_initData();
		dc7__behavior__resize.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__resize.f_initData = function()
		{
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__resize.f_initContent = function()
		{
		///h : self init
		dc7__behavior.f_initBehaviorClass({'selector_behavior':'window', 's_behavior_id':'resize', 'class_behavior':dc7__behavior__resize});
		dc7__behavior.f_initBehaviorClass({'s_behavior_id':'resize', 'class_behavior':dc7__behavior__resize});
		dc7__behavior.f_updateElements();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class dynamic methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__resize.prototype.f_init = function($o_config)
		{
		this.f_initData($o_config);
		this.f_initContent();
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__resize.prototype.f_initData = function($o_config)
		{
		this.__o_data = {};
		this.__o_data['jqo_element'] = $o_config['jqo_element'];
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__resize.prototype.f_initContent = function($o_config)
		{
		this.__o_data['jqo_element'].bind('resize orientationchange', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_updateContent(false);});
		dc7.__o_data['dom__jqo_document'].bind('click', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_updateContent(false);});		
		this.f_updateContent(true);
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__resize.prototype.f_updateContent = function($b_init)
		{
		///h : properties
		var $i_width = -1;		///h : stores x
		var $i_height = -1;		///h : stores y
		var $s_resize = null;		///h : stores scroll id

		///h : get current scroll values
		if(this.__o_data['jqo_element'][0] === dc7.__o_data['dom__jqo_window'][0])
			{
			$i_width = (this.__o_data['jqo_element'].width());
			$i_height = (this.__o_data['jqo_element'].height());
			}
		else
			{
			$i_width = this.__o_data['jqo_element'][0].offsetWidth();
			$i_height = this.__o_data['jqo_element'][0].offsetHeight();
			}
		$s_resize = ($i_width + '||' + $i_height);

		///h : check if scroll
		if($s_resize !== this.__o_data['s_resize'])
			{
			///h : update values
			this.__o_data['i_width'] = $i_width;
			this.__o_data['i_height'] = $i_height;
			this.__o_data['s_resize'] = $s_resize; 

			///h : trigger
			if($b_init !== true)
				{
				///h : event : scroll
				$s_event_id = ('dc7__behavior__resize__resize');
				dc7__events.f_trigger(this.__o_data['jqo_element'], $s_event_id, [this.__o_data], [0.8]);
				}
			}
		}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__behavior__resize.f_initSystem();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
