//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__behavior__size($o_config){this.f_construct($o_config);}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__behavior__size.__o_data = null;				///h : stores class data
	dc7__behavior__size.__o_config = null;				///h : stores class config

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__behavior__size.f_initSystem = function()
		{
		dc7__behavior__size.f_init();
		}
	

	/////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__behavior__size.prototype.f_construct = function($o_config)
		{
		this.f_init($o_config);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class static methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__size.f_init = function()
		{
		dc7__behavior__size.f_initData();
		dc7__behavior__size.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__size.f_initData = function()
		{
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__size.f_initContent = function()
		{
		///h : self init
		dc7__behavior.f_initBehaviorClass({'s_behavior_id':'size', 'class_behavior':dc7__behavior__size});
		dc7__behavior.f_updateElements();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__size.prototype.f_init = function($o_config)
		{
		this.f_initData($o_config);
		this.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__size.prototype.f_initData = function($o_config)
		{
		this.__o_data = {};
		this.__o_data['jqo_element'] = $o_config['jqo_element'];
		this.__o_data['jqo_base'] = (($o_config['jqo_base'] === undefined) ? $o_config['jqo_element'].parent() : this.__o_data['jqo_base']);
		this.__o_data['i_width'] = $o_config['i_width'];
		this.__o_data['i_height'] = $o_config['i_height'];
		this.__o_data['num_ratio'] = (this.__o_data['i_width'] / this.__o_data['i_height']);
		this.__o_data['s_type'] = $o_config['s_type'];
		this.__o_data['num_factor'] = (($o_config['num_factor'] === undefined) ? 1 : $o_config['num_factor']);
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__size.prototype.f_initContent = function($o_config)
		{
		dc7.__o_data['dom__jqo_window'].bind('resize load dc7__behavior__update', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_updateContent();});
		this.f_updateContent();
		}
	
	
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__size.prototype.f_updateContent = function($o_config)
		{
		if(this.__o_data['s_type'] === 'cover')
			{
			$i_width_base = Math.round(this.__o_data['jqo_base'].innerWidth());
			$i_height_base = Math.round(this.__o_data['jqo_base'].innerHeight());
			$num_ratio_base = ($i_width_base / $i_height_base);
			
			if($num_ratio_base > this.__o_data['num_ratio'])
				{
				$i_width = ($i_width_base);
				$i_height = Math.round($i_width * this.__o_data['num_ratio']);
				}
			else
				{
				$i_height = $i_height_base;
				$i_width = Math.round($i_height * this.__o_data['num_ratio']);
				
				
				}
			
			}
			
		///h : 
		num_factor = 0.3;
		
		$i_width = Math.round($i_width * this.__o_data['num_factor']);
		$i_height = Math.round($i_height * this.__o_data['num_factor']);

		///h : center
		$i_x = Math.round(($i_width_base * 0.5) - ($i_width * 0.5));
		$i_y = Math.round(($i_height_base * 0.5) - ($i_height * 0.5));

		///h : 
		this.__o_data['jqo_element'].css({'left':$i_x, 'top':$i_y, 'width':$i_width, 'height':$i_height});
		}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__behavior__size.f_initSystem();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
