/*
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
code:		plustic â€” massimo cardascia â€” www.plustic.de
code language:	js 5
code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
*/

///*	class definition /////////////////////////////////////////////////////////////////////////////////////
function dc7__behavior__backgroundparallax($o_config){this.f_construct($o_config);}
   
///*    class properties /////////////////////////////////////////////////////////////////////////////////////

///*    class system init ////////////////////////////////////////////////////////////////////////////////////
	dc7__behavior__backgroundparallax.f_initSystem = function()
		{
		dc7__behavior__backgroundparallax.f_init();
		}

///*    class constructor ////////////////////////////////////////////////////////////////////////////////////
	dc7__behavior__backgroundparallax.prototype.f_construct = function($o_config)
		{
		///h : init
		this.f_init($o_config);
		}

///*    public static functions //////////////////////////////////////////////////////////////////////////////

///*    public functions /////////////////////////////////////////////////////////////////////////////////////

///*    pivate static functions //////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class static methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__backgroundparallax.f_init = function()
		{
		///h :
		dc7__behavior__backgroundparallax.f_initData();
		
		///h :
		dc7__behavior__backgroundparallax.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__backgroundparallax.f_initData = function()
		{
		///h :
		
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__backgroundparallax.f_initContent = function()
		{
		///h : init behavior
		dc7__behavior.f_initBehaviorClass({'s_behavior_id':'dc7__behavior__backgroundparallax', 'class_behavior':dc7__behavior__backgroundparallax, 'selector_behavior':'.dc7__behavior__backgroundparallax'});
		dc7__behavior.f_updateElements();
		} 

 
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__backgroundparallax.prototype.f_init = function($o_config)
		{
		///h : update important values
		this.f_initData($o_config);

		///h : init engine
		this.f_initContent();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__backgroundparallax.prototype.f_initData = function($o_config)
		{
		///h : data 
		this.__o_data = {};
		this.__o_data['o_config'] = $o_config; 
		this.__o_data['jqo_element'] = $o_config['jqo_element'];
		this.__o_data['num_y'] = 0;
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__backgroundparallax.prototype.f_ruleOfThree = function($num_data__a__1, $num_data__a__2, $num_data__b__1)
		{
		///h : return
		return(($num_data__a__2 / $num_data__a__1) * $num_data__b__1);
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__backgroundparallax.prototype.f_lerp = function($num_start, $num_end, $num_amt)
		{
		///h :
		return((1 - $num_amt) * $num_start + $num_amt * $num_end);
		}
		

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__backgroundparallax.prototype.f_update = function()
		{
		///h :
		var $num_percentage = null;		///h :
		var $element__i_y = null;		///h :
		var $element__i_height = null;		///h :
		var $element__i_top = null;		///h :
		var $window__i_y = null;		///h :
		var $window__i_height = null;		///h :
		var $i_y = null;			///h :
		var $element__i_y__min = null;		///h :
		var $element__i_y__max = null;		///h :

		///h : 
		$window__i_y = jQuery(window).scrollTop();
		$window__i_height = jQuery(window).height();
		$parent__i_height = (this.__o_data['jqo_img'].height());
		$element__i_height = this.__o_data['jqo_image'].height();
		$element__i_distance = ($parent__i_height - $element__i_height); 
		$element__i_dif = ($element__i_height - $element__i_height);
		$element__i_top = this.__o_data['jqo_element'].offset().top;
		$element__i_y = ($element__i_top - $window__i_y + $element__i_height);
		$element__i_y__min = 0;
		$element__i_y__max = ($window__i_height + $element__i_height);
		$element__i_y = (($element__i_y <= $element__i_y__min) ? $element__i_y__min : $element__i_y);
		$element__i_y = (($element__i_y >= $element__i_y__max) ? $element__i_y__max : $element__i_y);
		$num_percentage = this.f_ruleOfThree($element__i_y__max, $element__i_y, 1);
		//$i_y = (-$element__i_distance * 0.50 + ($element__i_distance * $num_percentage));
//		$i_y = (-$element__i_distance * $num_percentage);
		
	//	$i_y = 0;
		///h :
	//	$i_y = 0;
	
		$i_y = (-$element__i_distance * $num_percentage);
		
	//	console.log($num_percentage);
 
		///h :
	//	this.__o_data['num_y'] = $i_y;
	//	this.__o_data['num_y'] = this.f_lerp(this.__o_data['num_y'], $i_y, 0.10);


		///h :
		TweenMax.set(this.__o_data['jqo_img'], {'y':$i_y});
		}
 

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__backgroundparallax.prototype.f_initContent = function()
		{
		///h : 
		var $uri_background = null;		///h :
		var $scope_data = null;			///h :

		///h :
		$uri_background = this.__o_data['jqo_element'].css('background-image').replace(/(url\(|\)|'|")/gi, '');

		///h :
		this.__o_data['jqo_element'].prepend('<div class=\'dc7__behavior__backgroundparallax__image\'></div>');
		this.__o_data['jqo_image'] = this.__o_data['jqo_element'].find('> .dc7__behavior__backgroundparallax__image');
		this.__o_data['jqo_image'].prepend('<div class=\'dc7__behavior__backgroundparallax__image__img\'></div>');
		this.__o_data['jqo_img'] = this.__o_data['jqo_image'].find('> .dc7__behavior__backgroundparallax__image__img');
		this.__o_data['jqo_img'].css({'background-image':'url(' + $uri_background + ')'});

		///h :
		this.__o_data['jqo_element'].addClass('_init__true');

		///h :
		$scope_data = this;
		TweenLite.ticker.addEventListener('tick', function(){$scope_data.f_update();});
		this.f_update();
		} 


///*    init class ///////////////////////////////////////////////////////////////////////////////////////////
jQuery(document).bind('ready', function(){dc7__behavior__backgroundparallax.f_initSystem();});

/*
--------------------------------------------------------------------------------------------------------------
description:
--------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------
*/