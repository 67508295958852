//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
   

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
 
///h : class
function dc7__behavior(){this.f_construct();}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__behavior.__o_data = null;				///h : stores class data
	dc7__behavior.__o_config = null;				///h : stores class config


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__behavior.f_initSystem = function()
		{
		///h :
		dc7__behavior.f_init();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class static methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init
	/**
	 * init
	 * init class calling all default functions like initenvironment, initdata
	 * initcontent.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior.f_init = function()
		{
		///: init config
		dc7__behavior.f_initData();

		///: init config
		dc7__behavior.f_initContent();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init data
	 * used to init all data later on used in the class context like
	 * configuration arrays etc.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior.f_initData = function()
		{
		dc7__behavior.__o_data = {};
		dc7__behavior.__o_data['a_behavior'] = [];
		dc7__behavior.__o_data['b_updating'] = false;
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init data
	 * used to init all data later on used in the class context like
	 * configuration arrays etc.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior.f_initBehaviorClass = function($storage_config)
		{
		///: check config
		$storage_config['selector_behavior'] = (($storage_config['selector_behavior'] === undefined) ? ('.dc7__behavior__' + $storage_config['s_behavior_id']) : $storage_config['selector_behavior']);
		$storage_config['b_selfupdate'] = (($storage_config['b_selfupdate'] === undefined) ? false : $storage_config['b_selfupdate']);
		$storage_config['s_dom_data'] = (($storage_config['s_dom_data'] === undefined) ? 'dc7' : $storage_config['s_dom_data']);

		///h : 
		dc7__behavior.__o_data['a_behavior'].push($storage_config);
	
		///h : 
		dc7__behavior.__o_data['i_behavior'] = dc7__behavior.__o_data['a_behavior'].length;
		
		///h : auto self update
		if($storage_config['b_selfupdate'] === true)
			{
			///h :
			dc7__behavior.f_updateElements();
			}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior.f_initContent = function()
		{
		///h : update elements short after ready
		dc7.__o_data['dom__jqo_window'].bind('dc7__behavior__load__ready__delayed0.1s', {}, function(){dc7__behavior.f_updateElements();});
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior.f_getElementData = function($jqo_element, $s_behavior_id)
		{
		///h :
		var $o_data = null;
		
		///h :
		$o_data = dc7__dom.f_getVar($jqo_element, ($s_behavior_id + '__o_data'));

		///h :
		return($o_data);
		}
		//
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior.f_initElement = function($jqo_element, $s_behavior_id, $class_behavior, $storage_config)
		{
		///h : 
		$s_class_root = ('dc7__behavior__' + $s_behavior_id);
		$storage_config = (($storage_config === undefined) ? {} : $storage_config);
		$s_dom_config = (($storage_config['s_dom_config'] === undefined) ? $s_class_root : $storage_config['s_dom_config']);

		///h : 
		$o_data = dc7__dom.f_getVar($jqo_element, ($s_class_root + '__o_data'));
		
		if($o_data === null) 
			{
			$o_config = {};
			$o_config['jqo_element'] = $jqo_element;
			$o_data = ($jqo_element.data($s_dom_config));
			
			///h : update data from element data attribute
			if($o_data !== undefined)
				{
				$o_config_dom = dc7__string.f_getDataObject($o_data, {'b_remove_brackets':false});
				for($s_var_name in $o_config_dom)
					{
					$o_config[$s_var_name] = $o_config_dom[$s_var_name];
					}
				}

			///h : add class
			$jqo_element.addClass($s_class_root);

			///h : init behavior
			dc7__dom.f_setVar($jqo_element, $s_class_root + '__o_data', new $class_behavior($o_config));
			}
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior.f_parseDOM = function()
		{
		dc7__behavior.f_updateElements();
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior.f_addBehavior = function($jqo_element, $s_class)
		{
		$jqo_element.addClass($s_class); 

		///h :
		dc7__behavior.f_updateElements();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior.f_updateElements = function($jqo_base)
		{
		///h : properties
		var $i_loop = -1;
		var $i_elements = -1;
		var $i_loop_child = -1;
		
		///h : 
		if(dc7__behavior.__o_data['b_updating'] === true)
			{
			return;
			}
		dc7__behavior.__o_data['b_updating'] = true;

		///h : default config
		$jqo_base = (($jqo_base === undefined) ? dc7.__o_data['jqo_html'] : $jqo_base);

		///h : parse behavior);
		for($i_loop = 0; $i_loop < dc7__behavior.__o_data['i_behavior']; $i_loop ++)
			{
			///h : 
			$storage_config = dc7__behavior.__o_data['a_behavior'][$i_loop];
			
			///h : 
			$jqo_elements_child = jQuery($storage_config['selector_behavior']);
			
			///h : 
			if($storage_config['selector_behavior'] === 'window')
				{
				$jqo_elements_child = dc7.__o_data['dom__jqo_window'];
				}
			else if($storage_config['selector_behavior'] === 'document')
				{
				$jqo_elements_child = dc7.__o_data['dom__jqo_document'];
				}
				
			///h : 
			$i_elements_child = $jqo_elements_child.length;
			
			///h : 
			for($i_loop_child = 0; $i_loop_child < $i_elements_child; $i_loop_child ++)
				{
				$jqo_element_child = jQuery($jqo_elements_child[$i_loop_child]);
				dc7__behavior.f_initElement($jqo_element_child, $storage_config['s_behavior_id'], $storage_config['class_behavior'], $storage_config);
				}
			}

		///h : 
		dc7__behavior.__o_data['b_updating'] = false;
		}


///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__behavior.f_initSystem();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
