//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__var(){this.f_construct();}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__var.__o_data = null;				///h : stores class data
	dc7__var.__o_config = null;				///h : stores class config


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__var.f_initSystem = function()
		{
		dc7__var.f_init();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init
	/**
	 * init
	 * init class calling all default functions like initenvironment, initdata
	 * initcontent.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__var.f_init = function()
		{
		///: init config
		dc7__var.f_initData();

		///: init config
		dc7__var.f_initContent();
		} 


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init data
	 * used to init all data later on used in the class context like
	 * configuration arrays etc.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__var.f_initData = function()
		{  
		dc7__var.__o_data = {};
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__var.f_initContent = function()
		{
		///h : short function - _defined
		dc7.system__f_addShortFunction('_defined', dc7__var.f_isDefined);

		///h : short function - _type
		dc7.system__f_addShortFunction('_type', dc7__var.f_getType);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault 
	 *
	 */
	dc7__var.f_extendVar = function($o_var__a, $o_var__b)
		{
		///h :
		var $s_loop = null;		///h :

		///h : loop
		for($s_loop in $o_var__b)
			{
			$o_var__a[$s_loop] = $o_var__b[$s_loop];
			}
	
		///h :
		return($o_var__a);
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__var.f_getType = function($o_var)
		{
		///h :
		var $s_type = null;	///h :
		
		///h :
		if(dc7__var.f_isDefined($o_var) === false)
			{
			///h :
			$o_var = 'null';
			}
		else
			{
			///h :
			$s_type = String(typeof($o_var)).toLowerCase();
			}

		///h :
		if($s_type === 'object')
			{
			///h :
			$s_type = jQuery.type($o_var).toLowerCase();
			}

		///h :
		return($s_type);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__var.f_isDefined = function($o_var_value)
		{
		///h :
		return((($o_var_value === undefined) || ($o_var_value === null)) === false);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__var.f_getCleanVar = function($o_var_value, $s_preset_id, $o_config)
		{
		///h : properties
		var $o_var_value__clean = null;		///h :
		var $o_var_value__type = null;		///h :
		var $i_data = -1;			///h :
		var $i_loop = -1;			///h :
		var $s_char = null;			///h :

		///h :
		if($o_var_value === null)
			{
			return($o_var_value);
			}

		///h :
		$o_var_value = (($o_var_value === undefined) ? null : $o_var_value);
		$s_preset_id = (($s_preset_id === undefined) ? 'simple__string' : $s_preset_id);

		///h :
		$o_var_value__clean = $o_var_value;
		$o_var_value__type = dc7__var.f_getType($o_var_value);

		///h :
		$o_config = ((dc7__var.f_isDefined($o_config) === false) ? {} : $o_config);
		$o_config['b_trim'] = (($o_config['b_trim'] === undefined) ? false : $o_config['b_trim']);
		$o_config['a_trim_chars'] = (($o_config['b_trim'] === undefined) ? null : $o_config['a_trim_chars']);
		$o_config['s_var_type'] = (($o_config['b_trim'] === undefined) ? null : $o_config['s_var_type']);
		$o_config['s_allowed_tags'] = (($o_config['b_trim'] === undefined) ? null : $o_config['s_allowed_tags']);
		$o_config['b_remove_linebreaks'] = (($o_config['b_trim'] === undefined) ? false : $o_config['b_remove_linebreaks']);
		$o_config['b_replace_htmlbreaks'] = (($o_config['b_trim'] === undefined) ? false : $o_config['b_replace_htmlbreaks']);
		$o_config['a_replace_multiple'] = (($o_config['a_replace_multiple'] === undefined) ? null : $o_config['a_replace_multiple']);
		$o_config['b_remove_specialchars'] = (($o_config['b_trim'] === undefined) ? false : $o_config['b_remove_specialchars']);
		$o_config['a_remove_chars'] = (($o_config['b_trim'] === undefined) ? null : $o_config['a_remove_chars']);
		$o_config['b_remove_numbers'] = (($o_config['b_trim'] === undefined) ? false : $o_config['b_remove_numbers']);
		$o_config['b_slug'] = (($o_config['b_slug'] === undefined) ? false : $o_config['b_slug']);
		$o_config['a_explode'] = (($o_config['a_explode'] === undefined) ? [',', '.', '/', '|', '||', ' '] : $o_config['a_explode']);
		$o_config['b_lowercase'] = (($o_config['b_lowercase'] === undefined) ? false : $o_config['b_lowercase']);
		$o_config['b_trailingslash'] = (($o_config['b_trailingslash'] === undefined) ? false : $o_config['b_trailingslash']);


		//////////////////////////////////////////////////////////////////////////////////////////////////////////////
		///h : presets
		$a_preset_id = ($s_preset_id.split('_'));
		$s_preset_group = $a_preset_id[0];
		

		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : presets - simple data type

		///h : presets - simple html
		if($s_preset_group === 'simple')
			{
			if($s_preset_id === 'simple__string')
				{
				$o_config['s_var_type'] = ('string');
				}
			if($s_preset_id === 'simple__array')
				{
				$o_config['s_var_type'] = ('array');
				}
			if($s_preset_id === 'simple__integer')
				{
				$o_config['s_var_type'] = ('integer');
				}
			}


		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : presets - uri

		///h : presets - simple html
		if($s_preset_group === 'uri')
			{
			///h :
			$o_config['s_var_type'] = 'string';
			$o_config['b_trim'] = true;
			$o_config['a_trim_chars'] = [' ', '/', '\\'];
			$o_config['a_replace_multiple'] = ['/'];

			///h :
			if($s_preset_id === 'uri__full')
				{
				}
			}


		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : data types casting

		///h : vartype casting
		if($o_config['s_var_type'] !== null)
			{
			//////////////////////////////////////////////////////////////////////////////////////
			///h : string
			if($o_config['s_var_type'] === 'string')
				{
				$o_var_value__clean = String($o_var_value__clean);
				
				///h : not valid
				if($o_var_value__clean.length === 0)
					{
				//	$o_var_value__clean = null;
					}
				}


			//////////////////////////////////////////////////////////////////////////////////////
			///h : array
			else if($o_config['s_var_type'] === 'array')
				{
				///h : explode string
				if(typeof($o_var_value__clean) === 'string')
					{
					///h : explode (default strings: ['.', ',', '|', '||', '/', ' '])
					$o_var_value__clean = dc7__string.f_replace($o_config['a_explode'], ',', $o_var_value__clean);
					$o_var_value__clean = $o_var_value__clean.split(',');
					}

				///h : not valid
				if($o_var_value__clean.length === 0)
					{
					$o_var_value__clean = null;
					}
				}
			}

		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : trim

		///h :
		if($o_config['b_trim'] === true) 
			{
			$o_var_value__clean = dc7__string.f_trim($o_var_value, $o_config['a_trim_chars']);
			}
	
		
		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : replace
		
		///h :
		if($o_config['a_replace_multiple'] !== null) 
			{
			if($o_var_value__clean !== null)
				{
				///h : loop
				$i_data = $o_config['a_replace_multiple'].length;
				for($i_loop = 0; $i_loop < $i_data; $i_loop++)
					{
					$s_char = $o_config['a_replace_multiple'][$i_loop];
					$reg_expression = new RegExp('' + $s_char + '{2,}', 'g');
					$o_var_value__clean = $o_var_value__clean.replace($reg_expression, $s_char);
					}
				}
			}


		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : case

		///h :
		if($o_config['b_lowercase'] === true) 
			{
			
			
			$o_var_value__clean = $o_var_value__clean.toLowerCase();
			}


		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : uri
		
		///h : 
		if($o_config['b_trailingslash'] === true) 
			{
			if($o_var_value__clean.slice(-1) != '/')
				{
				$o_var_value__clean += '/';
				}
			}
			


		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : special
		
		///h : uri
		if($s_preset_group === 'uri')
			{
			if($o_var_value__clean !== null)
				{
				///h : uri
				$o_var_value__clean = dc7__string.f_replace(':/', '://', $o_var_value__clean);
				}
			}
			

		///h :
		return($o_var_value__clean);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__var.f_initVar = function($o_var_value, $o_var_value__default, $s_var_type, $o_config, $s_id)
		{
		///h :
		$o_var_value__default = (($o_var_value__default === undefined) ? null : $o_var_value__default);
		$o_var_value = ((($o_var_value === undefined) || ($o_var_value === null)) ? $o_var_value__default : $o_var_value);
		$s_var_type = (($s_var_type === undefined) ? null : $s_var_type);
		$o_config = (($o_config === undefined) ? null : $o_config);

		///h : type casting
		if(($s_var_type !== undefined) && ($o_config !== null))
			{
			$o_var_value = dc7__var.f_getCleanVar($o_var_value, $s_var_type, $o_config);
			}

		///h :
		return($o_var_value);
		} 


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__var.f_initSystem();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
