//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__behavior__acall($o_config){this.f_construct($o_config);}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__behavior__acall.__o_data = null;				///h : stores class data
	dc7__behavior__acall.__o_config = null;				///h : stores class config
 

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__behavior__acall.f_initSystem = function()
		{
		dc7__behavior__acall.f_init();
		}
	

	/////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__behavior__acall.prototype.f_construct = function($o_config)
		{
		this.f_init($o_config);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class static methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__acall.f_init = function()
		{
		dc7__behavior__acall.f_initData();
		dc7__behavior__acall.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__acall.f_initData = function()
		{
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__acall.f_initContent = function()
		{
		///h : self init
		dc7__behavior.f_initBehaviorClass({'s_behavior_id':'acall', 'class_behavior':dc7__behavior__acall});
		dc7__behavior.f_initBehaviorClass({'s_behavior_id':'acall', 'class_behavior':dc7__behavior__acall, 'selector_behavior':'a'});
		dc7__behavior.f_updateElements();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__acall.prototype.f_init = function($o_config)
		{
		this.f_initData($o_config);
		this.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__acall.prototype.f_initData = function($o_config)
		{
		///h :
		var $uri_data = null;		///h :
			
		///h :
		this.__o_data = {};
		this.__o_data['jqo_element'] = $o_config['jqo_element'];
		this.__o_data['s_data'] = this.__o_data['jqo_element'].attr('href');
		
		
		this.__o_data['s_target'] = this.__o_data['jqo_element'].attr('target');
		this.__o_data['s_data'] = ((this.__o_data['s_data'] === undefined) ? '' : this.__o_data['s_data']);

		///h : modal special
		if(this.__o_data['s_target'] === '_modal')
			{
			///h :
			this.__o_data['s_data'] = ('[modal s_content_uri="' + this.__o_data['s_data'] + '"]');
			}

		///h : 
		this.__o_data['b_acall'] = ((this.__o_data['s_data'].indexOf('[') === 0) ? true : false);
 
		///h :
		if(this.__o_data['b_acall'] === true)
			{
			this.__o_data['o_data'] = dc7__string.f_getDataObject(this.__o_data['s_data']);
			this.__o_data['o_data']['jqo_element'] = this.__o_data['jqo_element'];
			this.__o_data['s_call_type'] = this.__o_data['o_data'][0];

			///h : check if acall type is valid
			if(['modal', 'popup', 'scrollto'].indexOf(this.__o_data['s_call_type']) === -1)
				{
				this.__o_data['b_acall'] = false;
				}
			else
				{
				///h : init content
				this[this.__o_data['s_call_type'] + '__f_initData']();
				}
			}

		///h :
		if(this.__o_data['b_acall'] === false)
			{
			///h :  
			$uri_data = this.__o_data['jqo_element'].attr('href');
			$s_target = this.__o_data['jqo_element'].attr('target');
			this.__o_data['jqo_element'].on('click', {'scope_data':this, 'uri_data':$uri_data, 's_target':$s_target}, function($event_data){$event_data.data['scope_data'].f_openNormalLink($event_data);});
			}
		}
		

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__acall.prototype.f_initContent = function($o_config)
		{
		///h :
		if(this.__o_data['b_acall'] === true)
			{
			///h : init content
			this[this.__o_data['s_call_type'] + '__f_initContent']();
 
			///h : remove href attribute and normal link behavior
			this.__o_data['jqo_element'].css({'cursor':'pointer'});
			this.__o_data['jqo_element'].attr('href', this.__o_data['o_data']['s_content_uri']);

			///h :
			this.__o_data['jqo_element'].bind('mouseenter mouseleave', {'o_base':this, 's_call_type':this.__o_data['s_call_type']}, function($o_event){$o_event.data['o_base'][$o_event.data['s_call_type'] + '__f_handler']($o_event.type, $o_event);});
			this.__o_data['jqo_element'].bind('click', {'o_base':this, 's_call_type':this.__o_data['s_call_type']}, function($o_event){$o_event.data['o_base'][$o_event.data['s_call_type'] + '__f_handler']($o_event.type, $o_event); $o_event.preventDefault(); return(false);});
			}
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__acall.prototype.f_openNormalLink__delayed = function($uri_data)
		{
		///h :
	//	location.href = $uri_data;
		window.top.location.href = $uri_data;
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 * 
	 */
	dc7__behavior__acall.prototype.f_openNormalLink = function($event_data)
		{
		///h : 
		var $b_external = null;		///h :
		var $b_newtarget = null;	///h :
		var $b_anchor = null;

		///h :
		$b_external = (dc7__request.f_isExternal($event_data.data['uri_data']));
		$b_anchor = (($event_data.data['uri_data'].indexOf('#') === 0) ? true : false);
		$b_newtarget = (($event_data.data['s_target'] === '_blank') ? true : false);

		///h : external
		if($b_external === true)
			{
			///h :
			window.open($event_data.data['uri_data'], '_blank');
			}
		///h : anchor
		else if($b_anchor === true)
			{
			///h :
			location.href = $event_data.data['uri_data'];
			}
		///h : target
		else if($b_newtarget === true)
			{
			///h :
			window.open($event_data.data['uri_data'], '_blank');
			}
		///h : normal
		else
			{
			///h :
			window.top.dc7__modal.f_close();

			///h :
			TweenMax.delayedCall(0.01, function($uri_data, $scope_data){$scope_data.f_openNormalLink__delayed($uri_data);}, [$event_data.data['uri_data'], this]);
			}
		
	
		///h :
		$event_data.stopPropagation();
		$event_data.preventDefault();
		return(false);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : popup
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__acall.prototype.popup__f_initData = function()
		{
		///h :
		this.__o_data['o_data']['s_content_uri'] = ((this.__o_data['o_data']['s_content_uri'] === undefined) ? 'http://www.google.de' : this.__o_data['o_data']['s_content_uri']);
		this.__o_data['o_data']['s_window_name'] = ((this.__o_data['o_data']['s_window_name'] === undefined) ? 'popup' : this.__o_data['o_data']['s_window_name']);
		this.__o_data['o_data']['s_specs'] = ((this.__o_data['o_data']['s_specs'] === undefined) ? 'width=400,height=500,top=20,left=20' : this.__o_data['o_data']['s_specs']);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__acall.prototype.popup__f_initContent = function()
		{
		}
	
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__acall.prototype.popup__f_handler = function($s_event_id)
		{
		if($s_event_id === 'click')
			{
			///h : open modal
			window.open(this.__o_data['o_data']['s_content_uri'], this.__o_data['o_data']['s_window_name'], this.__o_data['o_data']['s_specs']).focus();
			}
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : modal
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__acall.prototype.modal__f_initData = function()
		{
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__acall.prototype.modal__f_initContent = function()
		{
		///h : properties
		var $s_config = null;	///h : 
		var $o_config = null;	///h : 

		///h : add modal class
		this.__o_data['jqo_element'].addClass('dc7__modal__call');

		///h : update data object
		this.__o_data['jqo_element'].data('dc7__modal', this.__o_data['s_data']);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__acall.prototype.modal__f_handler = function($s_event_id, $event_data)
		{
		///h :
		if(jQuery('.neos-backend').length === 1)
			{
			///h :
			return;
			}

		///h :
		if($s_event_id === 'click')
			{
			///h : open modal
			dc7__modal.f_open(this.__o_data['o_data']);
			}
		}  

		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : scrollto
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__acall.prototype.scrollto__f_initData = function()
		{
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__acall.prototype.scrollto__f_initContent = function()
		{
		}
	
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__acall.prototype.scrollto__f_handler = function($s_event_id)
		{
		
		///h :
		if(jQuery('.neos-backend').length === 1)
			{
			///h :
			return;
			}

		if($s_event_id === 'click')
			{
			///h :
			dc7__scroller.f_scroll({'s_target_selector':this.__o_data['o_data']['s_target_selector']});
			}
		}

	

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__behavior__acall.f_initSystem();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
