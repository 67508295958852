//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__timeline(){this.f_construct();}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__timeline.__o_data = null;				///h : stores class data
	
 
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__timeline.f_initSystem = function()
		{
		dc7__timeline.f_init();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init
	/**
	 * init
	 * init class calling all default functions like initenvironment, initdata
	 * initcontent.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__timeline.f_init = function()
		{
		///: init config
		dc7__timeline.f_initData();

		///: init config
		dc7__timeline.f_initContent();
		} 


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init data
	 * used to init all data later on used in the class context like
	 * configuration arrays etc.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__timeline.f_initData = function()
		{
		///h :
		dc7__timeline.__o_data = {};
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__timeline.f_initContent = function()
		{
		}
		
	
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__timeline.f_addTimeline = function($jqo_element, $s_id, $o_config)
		{
		///h :
		var $i_elements = null;			///h :
		var $i_loop = null;			///h :
		var $timeline_data = null;		///h :
		var $o_data = null;			///h :
		var $jqo_element__single = null;	///h :
		var $s_loop = null;			///h :

		///h :
		if(dc7__dom.f_isValid($jqo_element) === false)
			{
			///h :
			return;
			}
		
		///h :
		$o_config = (($o_config === undefined) ? {} : $o_config);
		$o_config['jqo_element'] = $jqo_element;
		$o_config['o_events'] = (($o_config['o_events'] === undefined) ? null : $o_config['o_events']);
		$o_config['b_reset'] = (($o_config['b_reset'] === undefined) ? false : $o_config['b_reset']);
		$o_config['s_dom_id'] = dc7__dom.f_getID($o_config['jqo_element']);
		$o_config['s_id'] = $s_id;
		
		
		
		///h :
		if(window[$s_id] === undefined)
			{
			///h :
			return;
			}

		///h :
		$timeline_data = (dc7__timeline.f_getTimeline($jqo_element, $s_id));
		if(($timeline_data !== null) && ($o_config['b_reset'] !== true))
			{
			///h :
			return;
			}

		///h :
		$i_elements = $jqo_element.length;
		if($i_elements > 1)
			{
			///h :
			for($i_loop = 0; $i_loop < $i_elements; $i_loop++)
				{
				///h :
				$jqo_element__single = jQuery($jqo_element[$i_loop]);

				///h :
				if($jqo_element__single.length === 1)
					{
					///h :
					dc7__timeline.f_addTimeline($jqo_element__single, $s_id, $o_config);
					}
				}

			///h :
			return;
			}
			
		///h :
		$timeline_data = window[$s_id].f_getTimeline($o_config);

		///h : 
		dc7__dom.f_setVar($jqo_element, ('dc7__timeline__' + $s_id), $timeline_data);

		///h : auto event binding
		if($o_config['o_events'] !== null)
			{
			///h : add events
			for($s_loop in $o_config['o_events'])
				{
				///h : 
				$o_data = $o_config['o_events'][$s_loop];

				///h : bind 
				$jqo_element.bind($s_loop, {'jqo_element':$jqo_element, 's_type':$s_loop, 's_id':$s_id, 's_call':$o_data['s_call'], 'o_data':$o_data}, function($o_event){dc7__timeline.f_control($o_event.data['jqo_element'], $o_event.data['s_id'], $o_event.data['s_call'], $o_event.data['o_data']);});
				}
			}
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__timeline.f_control = function($jqo_element, $s_id, $s_call, $o_config)
		{
		///h :
		var $timeline_data = null;	///h :

		///h : 
		$timeline_data = dc7__dom.f_getVar($jqo_element, ('dc7__timeline__' + $s_id));
		if($timeline_data === null)
			{
			///h :
			return; 
			}
		
		///h :
		$o_config = (($o_config === undefined) ? {} : $o_config);
		$o_config['num_timescale'] = (($o_config['num_timescale'] === undefined) ? 1 : $o_config['num_timescale']);
		$o_config['num_position'] = (($o_config['num_position'] === undefined) ? null : $o_config['num_position']);
		
		///h :
		if($o_config['num_position'] !== null)
			{
			///h :
			$timeline_data.pause($o_config['num_position']);
			}
  
		///h :
		$timeline_data[$s_call]().timeScale($o_config['num_timescale']);
		}
		
//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */ 
	dc7__timeline.f_getTimeline = function($jqo_element, $s_id)
		{
		///h : 
		return(dc7__dom.f_getVar($jqo_element, ('dc7__timeline__' + $s_id)));
		}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__timeline.f_initSystem();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

/*

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : 01.04.2017 - wp.not-yet-existing.com - stadler website animations
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : linebuttons - bind timeline to hover animations
$o_config = {};
$o_config['o_events'] = {};
$o_config['o_events']['mousenter'] = {'s_call':'play', 'num_timescale':1.0};
$o_config['o_events']['mouseleave'] = {'s_call':'reverse', 'num_timescale':1.3};
dc7__timeline.f_addTimeline(this.__o_data['jqo_linebutton'], 'project__timeline__linebutton', $o_config);


 */