/*
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
code:		plustic â€” massimo cardascia â€” www.plustic.de
code language:	js 5
code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
*/ 

///*	class definition /////////////////////////////////////////////////////////////////////////////////////
function project__behavior__mousefollow($o_config){this.f_construct($o_config);}
   
///*    class properties /////////////////////////////////////////////////////////////////////////////////////

///*    class system init ////////////////////////////////////////////////////////////////////////////////////
	project__behavior__mousefollow.f_initSystem = function()
		{
		project__behavior__mousefollow.f_init();
		}

///*    class constructor ////////////////////////////////////////////////////////////////////////////////////
	project__behavior__mousefollow.prototype.f_construct = function($o_config)
		{
		///h : init
		this.f_init($o_config);
		}

///*    public static functions //////////////////////////////////////////////////////////////////////////////

///*    public functions /////////////////////////////////////////////////////////////////////////////////////

///*    pivate static functions //////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class static methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__mousefollow.f_init = function()
		{
		///h :
		project__behavior__mousefollow.f_initData();
		
		///h :
		project__behavior__mousefollow.f_initContent();
		}
 
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__mousefollow.f_initData = function()
		{
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__mousefollow.f_initContent = function()
		{
		///h : init behavior
		dc7__behavior.f_initBehaviorClass({'s_behavior_id':'project__behavior__mousefollow', 'class_behavior':project__behavior__mousefollow, 'selector_behavior':'html'});
		dc7__behavior.f_updateElements();
		}

 
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__mousefollow.prototype.f_init = function($o_config)
		{
		
		///h :
		if(jQuery('.neos-backend').length === 1)
			{
			///h :
			return;
			}
			
		///h :
		if(dc7__grid.f_isTouchscreen() === true)
			{
			///h :
			return;
			}
			
		///h : 
		jQuery('head').append('<style>html *{cursor: none !important;}</style>');
 

		///h : 
		if(window.top !== window.self)
			{
			///h :
			return;
			}

		///h : update important values
		this.f_initData($o_config);

		///h : init engine
		this.f_initContent();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__mousefollow.prototype.f_initData = function($o_config)
		{
		///h : data 
		this['o_data'] = {};
		this['o_data']['jqo_mouse'] = null;
		this['o_data']['jqo_follow'] = null;
		this['o_data']['num_mousex'] = null;
		this['o_data']['num_mousey'] = null;
		this['o_data']['num_followx'] = null;
		this['o_data']['num_followy'] = null;
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 * 
	 */
	project__behavior__mousefollow.prototype.f_click = function()
		{
		///h :
		}
			


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 * 
	 */
	project__behavior__mousefollow.prototype.f_initContent = function()
		{
		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : properties

		///h :
		var $scope_data = null;		///h :
		var $jqo_a = null;		///h :
		var $i_loop = null;		///h :
		var $i_data = null;		///h :
		var $html_link = null;		///h :
		var $scope_data = null;			///h :

		
		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : remove all cursor
		
		///h :
		top.scope_data = this;
		top.f_updateMousePos = function($i_x, $i_y)
			{
			///h :
			top.scope_data.f_updateMouse(null, $i_x, $i_y);
	 		}
		
		///h :
		jQuery('body').append('<div class=\'_mousefollow__mouse\'><div class=\'_mousefollow__mouse__content\'></div><div class=\'_mousefollow__mouse__text\'><p>text</p></div></div>');
		jQuery('body').append('<div class=\'_mousefollow__follow\'><div class=\'_mousefollow__follow__content\'></div></div>'); 

		///h :
		this['o_data']['jqo_mouse'] = jQuery('._mousefollow__mouse');
		this['o_data']['jqo_mousecontent'] = jQuery('._mousefollow__mouse__content');
		this['o_data']['jqo_mousetext'] = jQuery('._mousefollow__mouse__text');
		this['o_data']['jqo_follow'] = jQuery('._mousefollow__follow');

		///h : 
		jQuery(document).on('mousemove mouseenter mouseleave', {'scope_data':this}, function($event_data){$event_data.data['scope_data'].f_updateMouse($event_data);});
 
		///h :
		$scope_data = this;
		TweenLite.ticker.addEventListener('tick', function(){$scope_data.f_updateFollow();});
		this.f_updateFollow(true);

		///h :
		$jqo_a = jQuery('a, ._mousefollow');
		$i_data = $jqo_a.length;

		///h :
		for($i_loop = 0; $i_loop < $i_data; $i_loop++)
			{
			///h :
			$jqo_dom = jQuery($jqo_a[$i_loop]);
			$html_link = $jqo_dom.html();
 
			///h : special case mousefollowtext by string part
			if($html_link.indexOf('||') > 0)
				{
				///h :
				$s_mousefollowtext = $html_link.split('||')[1];
				$jqo_dom.data('_mousefollowtext', $s_mousefollowtext);
				$jqo_dom.text($html_link.split('||')[0]);
				}
			}

		///h :
		for($i_loop = 0; $i_loop < $i_data; $i_loop++)
			{
			///h :
			$jqo_dom = jQuery($jqo_a[$i_loop]);
			$jqo_dom.on('mouseenter mouseout', {'scope_data':this, 'jqo_dom':$jqo_dom}, function($event_data){$event_data.data['scope_data'].f_updateHover($event_data);});
			$jqo_dom.on('click', {'scope_data':this, 'jqo_dom':$jqo_dom}, function($event_data){$event_data.data['scope_data'].f_click($event_data);});
			}
		}
	


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - dynamic init content
	/**
	 * public static function â€” f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void
	 *
	 */
	project__behavior__mousefollow.prototype.f_updateMouse = function($event_data, $i_clientX, $i_clientY)
		{
		///h :
		var $s_display = null;		///h :
		var $o_pos = null;		///h :

		///h :
		if($event_data === null)
			{
			///h :
//			$s_display = 'flex';
			$o_pos = jQuery('.dc7__modal_content').first().position();
			this['o_data']['num_mousex'] = ($i_clientX + $o_pos.left);
			this['o_data']['num_mousey'] = ($i_clientY + $o_pos.top);
			}
		///h :
		else
			{
			///h :
			$s_display = (($event_data.type === 'mouseleave') ? 'none' : 'flex');
			this['o_data']['num_mousex'] = $event_data.clientX;
			this['o_data']['num_mousey'] = $event_data.clientY;
			}

		///h :
		this['o_data']['jqo_mouse'].css({'display':$s_display});
		this['o_data']['jqo_follow'].css({'display':$s_display});
		
		
		///h :
		TweenMax.set(this['o_data']['jqo_mouse'], {'x':this['o_data']['num_mousex'], 'y':this['o_data']['num_mousey']});
		}
		
		

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - dynamic init content
	/**
	 * public static function â€” f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void
	 *
	 */
	project__behavior__mousefollow.prototype.f_updateHover = function($event_data)
		{
		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : properties

		///h :
		var $b_hover = null;		///h :
		var $s_text = null;		///h :
		var $jqo_dom = null;		///h :

		///h ;
		$b_hover = (($event_data.type === 'mouseenter') ? true : false);
		$jqo_dom = $event_data.data['jqo_dom'];


		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : properties

		///h :
		dc7__dom.f_setClassVar(this['o_data']['jqo_mousecontent'], '_hover', $b_hover);
		dc7__dom.f_setClassVar(this['o_data']['jqo_mousetext'], '_hover', $b_hover);
		
		
	//	dc7__dom.f_setState(this['o_data']['jqo_mousecontent'], 'hover', $b_hover);
	//	dc7__dom.f_setState(this['o_data']['jqo_mousetext'], 'hover', $b_hover);


		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : text
 
		///h :
		if($event_data.type === 'mouseenter')
			{
			///h :
			$s_text = $jqo_dom.data('_mousefollowtext');
			$s_text = dc7__var.f_initVar($s_text, '');

			///h :
			this['o_data']['jqo_mousetext'].html('<p>' + $s_text + '</p>');
			
			///h :
			TweenMax.fromTo(this['o_data']['jqo_mousetext'], 0.40, {'autoAlpha':0.00}, {'autoAlpha':1.00, 'ease':'Expo.easeOut'});
			}
		///h :
		else
			{
			this['o_data']['jqo_mousetext'].html('');
			}
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - dynamic init content
	/**
	 * public static function â€” f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 *
	 */
	project__behavior__mousefollow.prototype.f_updateFollow = function($b_init)
		{
		///h :
		this['o_data']['num_followx'] = dc7__math.f_lerp(this['o_data']['num_followx'], this['o_data']['num_mousex'], 0.15);
		this['o_data']['num_followy'] = dc7__math.f_lerp(this['o_data']['num_followy'], this['o_data']['num_mousey'], 0.15);


		///h :
		TweenMax.set(this['o_data']['jqo_follow'], {'x':this['o_data']['num_followx'], 'y':this['o_data']['num_followy']});
		}
		


///*    init class ///////////////////////////////////////////////////////////////////////////////////////////
jQuery(document).bind('ready', function(){project__behavior__mousefollow.f_initSystem();});

/*
--------------------------------------------------------------------------------------------------------------
description:
--------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------
*/